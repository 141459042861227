import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Translations from "./components/Translations/Translations";
import Protected from "./components/Protected/Protected";
import Tasks from "./pages/Tasks/Tasks";
import DataEntry from "./pages/DataEntry/DataEntry";
import ErrorPage from "./pages/Error/ErrorPage"; // Import a Sorry page
import Cases from "./pages/Cases/Cases";
import Case from "./pages/Cases/Case/Case";
import Header from "./components/Header/Header";
import EmptyState from "./components/EmptyState/EmptyState";
import LoadingPage from "./components/LoadingPage/LoadingPage";
import AlertNotification from "./components/AlertNotification/AlertNotification";
import { apiClient, setupInterceptors } from "./services/apiService";
import { initKeycloak, getToken } from "./utils/keykloak";
import { useAlert } from "./contexts/AlertContext";
import { useTranslation } from "react-i18next";
function App() {
  const [loading, setLoading] = useState(false);
  const [hasToken, setHasToken] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [error, setError] = useState(null);
  const [, setKeycloakInitialized] = useState(false);
  const { showAlert } = useAlert();
  const [t, i18n] = useTranslation("common");
  useEffect(() => {
    setupInterceptors(showAlert, t); // Setup interceptors with the showAlert function
  }, [showAlert, i18n.language, t]);

  useEffect(() => {
    const initializeKeycloak = async () => {
      console.log("Keycloack set up");
      try {
        setLoading(true);
        const response = await initKeycloak(); // Await the Keycloak initialization
        if (response?.error === "iframeTimeout") {
          // Redirect to "Sorry" page for iframe timeout issues
          setError("iframeTimeout");
        } else if (response?.error) {
          // Handle other authentication failures
          setError(response.error);
        } else {
          const accessToken = getToken();
          if (accessToken) {
            setHasToken(true);
            setAuthenticated(true);
            apiClient.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${accessToken}`;
          }
        }
      } catch (error) {
        console.error("Unexpected error during Keycloak initialization", error);
        setError(error);
      } finally {
        setKeycloakInitialized(true); // Mark Keycloak as initialized
        setTimeout(() => {
          setLoading(false);
        }, 2000); // Set loading to false after initialization
      }
    };
    initializeKeycloak();
  }, []);

  if (loading) {
    return <LoadingPage />; // Show loading state
  }

  return (
    <>
      {/* <Translations /> */}
      <AlertNotification />
      <Router>
        {(error || hasToken) && (
          <Routes>
            {error === "iframeTimeout" && (
              <>
                <Route path="/error" element={<ErrorPage />} />
                <Route path="*" element={<Navigate to="/error" replace />} />
              </>
            )}

            {hasToken && !error && (
              <>
                <Route
                  path="/"
                  element={
                    authenticated ? <Navigate to="/tasks" replace /> : <></>
                  }
                />
                <Route element={<Header />}>
                  <Route
                    path="/tasks"
                    element={<Protected Component={Tasks} />}
                  />
                  <Route
                    path="/cases"
                    element={<Protected Component={Cases} />}
                  />
                  <Route
                    path="/data-entry"
                    element={<Protected Component={DataEntry} />}
                  />
                  <Route
                    path="/cases/:id"
                    element={<Protected Component={Case} />}
                  />
                  <Route
                    path="/cases/create/:id"
                    element={<Protected Component={Case} />}
                  />
                  <Route path="*" element={<EmptyState />} />
                </Route>
              </>
            )}
          </Routes>
        )}
      </Router>
    </>
  );
}

export default App;
