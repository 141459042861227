import ReactDOM from "react-dom/client";
import App from "./App.jsx";
//import 'bootstrap/dist/css/bootstrap.min.css';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import "./i18n";

import { AlertProvider } from "./contexts/AlertContext"; // Import AlertProvider

// Wrap the App with the AlertProvider
ReactDOM.createRoot(document.getElementById("root")).render(
  <I18nextProvider i18n={i18next}>
    <AlertProvider>
      <App />
    </AlertProvider>
  </I18nextProvider>
);
