import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SelectComponent from "../../../Select/Select";
import { User, Calendar } from "react-iconly";
import IconComponent from "../../../IconComponent/IconComponent";
import CalendarComponent from "../../../Calendar/Calendar";
import { useState, useEffect } from "react";
import { fetchUsers } from "../../../../utils/keykloak";
import assigneesJson from "../../../../../public/demo/assignees.json";
import { useTranslation } from "react-i18next";
const Info = ({ info, handleChange, errors }) => {
  const [optionsAssignee, setOptionsAssignee] = useState(null);
  const [t, i18n] = useTranslation("common");
  useEffect(() => {
    //fetchUsers();
    setOptionsAssignee(assigneesJson);
  }, []);

  return (
    <Row>
      {optionsAssignee != null && (
        <Col
          xs={12}
          xl={6}
          xxl="auto"
          className="d-flex align-items-start mb-xl-0 mb-2"
        >
          <IconComponent
            icon={<User label="User" primaryColor="#264687" />}
            text={`${t("forms.labels.general.assignee")}:`}
          />
          <SelectComponent
            options={optionsAssignee}
            handleChange={handleChange}
            controlId="assignee_id"
            name="assignee_id"
            defaultVal={info.assignee_id ? info.assignee_id : null}
            placeholder={t("forms.labels.select.assignee")}
            error={errors.error_assignee}
            isRequired={false}
            clearSetting={true}
          />
        </Col>
      )}

      <Col
        xs={12}
        xl={6}
        xxl="auto"
        className="d-flex align-items-start mb-xl-0 mb-2"
      >
        <IconComponent
          icon={<Calendar label="Calendar" primaryColor="#264687" />}
          text={`${t("forms.labels.general.due_date")}:`}
        />
        <CalendarComponent
          label="duedate_at"
          handleChange={handleChange}
          date={info.duedate_at}
          error={errors.error_duedate_at}
        />
      </Col>
    </Row>
  );
};

export default Info;
