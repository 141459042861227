import Card from "../../../Card/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SimpleMDE from "react-simplemde-editor";
import DueDate from "../DueDate";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
const FormC = ({
  id,
  onInputChange,
  editorValue,
  setEditorValue,
  formData,
}) => {
  const [t, i18n] = useTranslation("common");
  const CardHeader = ({ title }) => {
    return (
      <Container fluid className="py-3">
        <Row>
          <Col xs={12}>
            <h3 className="p fw-semibold text-primary h4">{title}</h3>
          </Col>
        </Row>
      </Container>
    );
  };

  const CardBody = ({ body }) => {
    return (
      <Container fluid className="pb-3">
        <Row>
          <Col xs={12}>{body}</Col>
        </Row>
      </Container>
    );
  };

  const handleEditorChange = (editor) => {
    let value = editor.display.input.prevInput;
    setEditorValue(value);
    onInputChange("modification_text", "modification_text", value);
  };

  const formCDATE = (formData) => {
    return (
      <DueDate
        title={t("forms.labels.formC.response_deadline")}
        onInputChange={onInputChange}
        value={formData && formData.due_date ? formData.due_date : ""}
        content={[
          t("forms.labels.formC.rejection_warning"),
          t("forms.labels.formC.acceptance_consequences"),
        ]}
      />
    );
  };

  return (
    <>
      <div className="section">
        <Card
          header={
            <CardHeader
              title={t("forms.labels.formC.partial_approval_notice")}
            />
          }
          body={
            <CardBody
              body={
                <>
                  <Form.Label htmlFor="content" className="visually-hidden">
                    Content
                  </Form.Label>
                  <SimpleMDE
                    id="content"
                    value={editorValue}
                    events={{
                      blur: handleEditorChange,
                    }}
                  />
                </>
              }
            />
          }
        />
      </div>
      <div className="section">
        <Card body={formCDATE(formData)} />
      </div>
    </>
  );
};
export default FormC;
