import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Forms from "../../../Forms/Forms";
import TableLabel from "../../../Tables/TableLabel/TableLabel";
const AddParticipant = ({
  setSideNavExpanded,
  handleSubmit,
  handleCancel,
  options,
}) => {
  const [formData, setFormData] = useState(null);
  const [t, i18n] = useTranslation("common");
  const [dataform, setDataForm] = useState([
    {
      type: "select",
      fromtype: "select",
      label: t("forms.labels.formA.status_code") + ":",
      options: [
        { value: "01", label: t("forms.codes.parties.claimant_label") },
        { value: "02", label: t("forms.codes.parties.defendant_label") },
        { value: "03", label: t("forms.codes.parties.claimant_repr_label") },
        { value: "04", label: t("forms.codes.parties.defendant_repr_label") },
        {
          value: "05",
          label: t("forms.codes.parties.claimant_auth_label"),
        },
        {
          value: "06",
          label: t("forms.codes.parties.defendant_auth_label"),
        },
      ],
      value: "01",
      name: "code",
      placeholder: t("forms.labels.formA.status_code"),
      class: "",
      controlId: "code",
    },
    {
      type: "text",
      fromtype: "text",
      label: t("forms.labels.general.name") + ":",
      name: "participant_name",
      placeholder: t("forms.labels.general.full_name"),
      value: "",
      disabled: false,
    },
    {
      type: "text",
      label: t("forms.labels.general.identification_code") + ":",
      fromtype: "text",
      name: "participant_idcode",
      placeholder: "Code",
      value: "",
      disabled: false,
    },
    {
      type: "text",
      label: t("forms.labels.general.address") + ":",
      fromtype: "text",
      name: "participant_address",
      placeholder: t("forms.labels.general.address"),
      value: "",
      disabled: false,
    },
    {
      type: "number",
      label: t("forms.labels.general.postal_code") + ":",
      fromtype: "text",
      name: "participant_postalcode",
      placeholder: t("forms.labels.general.postal_code"),
      value: "",
      disabled: false,
    },
    {
      type: "text",
      label: t("forms.labels.general.city") + ":",
      fromtype: "text",
      name: "participant_city",
      placeholder: t("forms.labels.general.city"),
      value: "",
      disabled: false,
    },
    {
      type: "text",
      label: t("forms.labels.general.country") + ":",
      fromtype: "text",
      name: "participant_country",
      placeholder: t("forms.labels.general.country"),
      value: "",
      disabled: false,
    },
    {
      type: "text",
      label: t("forms.labels.general.phone") + ":",
      fromtype: "text",
      name: "participant_phone",
      placeholder: t("forms.labels.general.phone"),
      value: "",
      disabled: false,
    },
    {
      type: "text",
      label: t("forms.labels.general.fax") + ":",
      fromtype: "text",
      name: "participant_fax",
      placeholder: t("forms.labels.general.fax"),
      value: "",
      disabled: false,
    },
    {
      type: "email",
      label: t("forms.labels.general.email") + ":",
      fromtype: "text",
      name: "participant_email",
      placeholder: t("forms.labels.general.email"),
      value: "",
      disabled: false,
    },
    {
      type: "text",
      label: t("forms.labels.general.occupation") + ":",
      fromtype: "text",
      name: "participant_occupation",
      placeholder: t("forms.labels.general.occupation"),
      value: "",
      disabled: false,
    },
    {
      type: "text",
      label: t("forms.labels.select.other_details") + ":",
      fromtype: "text",
      name: "participant_details",
      placeholder: t("forms.labels.general.details"),
      value: "",
      disabled: false,
    },
  ]);

  useEffect(() => {
    if (options) {
      // Update formData
      setFormData({
        code: options.participation_code || "",
        participant_name: `${options.first_name || ""} ${
          options.surname || ""
        }`.trim(),
        participant_idcode: options.identificationCodeString || "",
        participant_address: options.address || "",
        participant_postalcode: options.postal_code || "",
        participant_city: options.city || "",
        participant_country: options.country || "",
        participant_phone: options.phone || "",
        participant_fax: options.fax || "",
        participant_email: options.email || "",
        participant_occupation: options.occupation || "",
        participant_details: options.other_details || "",
      });
      setDataForm((prevDataForm) =>
        prevDataForm.map((field) => {
          switch (field.name) {
            case "code":
              return {
                ...field,
                value: options.participation_code || field.value,
              };
            case "participant_name":
              return {
                ...field,
                value: `${options.first_name || ""} ${
                  options.surname || ""
                }`.trim(),
              };
            case "participant_idcode":
              return {
                ...field,
                value: options.identificationCodeString || field.value,
              };
            case "participant_address":
              return {
                ...field,
                value: options.address || field.value,
              };
            case "participant_postalcode":
              return {
                ...field,
                value: options.postal_code || field.value,
              };
            case "participant_city":
              return {
                ...field,
                value: options.city || field.value,
              };
            case "participant_country":
              return {
                ...field,
                value: options.country || field.value,
              };
            case "participant_phone":
              return {
                ...field,
                value: options.phone || field.value,
              };
            case "participant_fax":
              return {
                ...field,
                value: options.fax || field.value,
              };
            case "participant_email":
              return {
                ...field,
                value: options.email || field.value,
              };
            case "participant_occupation":
              return {
                ...field,
                value: options.occupation || field.value,
              };
            case "participant_details":
              return {
                ...field,
                value: options.other_details || field.value,
              };
            default:
              return field;
          }
        })
      );
    }
  }, [options]);

  const formRef = useRef(null);
  const resetData = () => {
    const newDataForm = dataform.map((item) => ({
      ...item,
      value: "", // Reset the value to an empty string
    }));
    setDataForm(newDataForm);
    setFormData(null);
    formRef.current.reset();
  };

  const handleCancelParticipants = (e) => {
    // Update the form data state with the cleared values
    handleCancel(e);
    resetData();
  };
  const handleSubmitParticipants = (e) => {
    console.log("Form submitted with data:", formData);
    handleSubmit(e);
    resetData();
  };

  const handleInputChange = (name, value) => {
    let newValue = value;

    // Check if value is an object with 'value' and 'label' properties
    if (value && value.value) {
      newValue = value.value; // Extract the value only
    }

    // Update formData state with the new value
    console.log(newValue);
    setFormData({ ...formData, [name]: newValue });
  };

  return (
    <>
      <div className="sidebar-wrapper">
        <Container>
          <Row>
            <Col xs={12}>
              <TableLabel label={t("components.buttons.add_participant")} />
            </Col>
          </Row>
        </Container>
      </div>

      <Forms
        data={dataform}
        hasSubmit={true}
        submitLabel={t("components.buttons.add")}
        onSubmit={handleSubmitParticipants}
        onCancel={handleCancelParticipants}
        disabled={false}
        handleInputChange={handleInputChange}
        formRef={formRef}
      />
    </>
  );
};

export default AddParticipant;
