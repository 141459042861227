import { createContext, useContext, useState } from "react";

// Create Alert Context
export const AlertContext = createContext();

// Custom hook to use the AlertContext
export const useAlert = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error("useAlert must be used within an AlertProvider");
  }
  return context;
};

// AlertProvider Component
export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    type: "info", // Default type: 'info', 'success', 'warning', 'danger'
  });

  // Function to show an alert
  const showAlert = ({ message, type = "info", duration = 5000 }) => {
    setAlert({ show: true, message, type });

    // Automatically hide the alert after a duration (default 5s)
    if (duration) {
      setTimeout(() => {
        hideAlert();
      }, duration);
    }
  };

  // Function to hide the alert
  const hideAlert = () => {
    setAlert({ show: false, message: "", type: "info" });
  };

  return (
    <AlertContext.Provider value={{ alert, showAlert, hideAlert }}>
      {children}
    </AlertContext.Provider>
  );
};
