import Accordion from "react-bootstrap/Accordion";
import { Plus } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import codesJson from "../../../public/demo/codes.json";
import TableParties from "../../components/Tables/TableLayout/TableParties";
import TableLabel from "../../components/Tables/TableLabel/TableLabel";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./AccordionTable.scss";

const AccordionTable = ({
  labeltitle,
  isCreateCasePage,
  setSideNavExpanded,
  parties,
  setSingleParty,
}) => {
  const [partiesCodes, setPartiesCodes] = useState(null);
  const [t, i18n] = useTranslation("common");

  const getTranslatedOptions = (codes) => {
    if (!codes || !Array.isArray(codes)) return [];
    return codes.map((code) => ({
      value: code.value,
      label: t(`forms.codes.parties.${code.label}`, "Unknown Label"), // Handle missing labels gracefully
    }));
  };
  useEffect(() => {
    if (codesJson && Array.isArray(codesJson)) {
      const translatedOptions = getTranslatedOptions(codesJson);
      setPartiesCodes(translatedOptions);
    } else {
      console.error("Invalid codesJSON format");
      setPartiesCodes([]);
    }
  }, []);

  const openSideBAr = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setSideNavExpanded(true);
  };

  const getPartyLabel = (participationCode) => {
    const codeEntry = partiesCodes.find(
      (code) => code.value === participationCode
    );
    if (codeEntry) {
      // Use the `t` function to translate the label
      return t(codeEntry.label);
    }
    return "Unknown Role";
  };

  return (
    <Accordion
      defaultActiveKey="0"
      className={`${isCreateCasePage ? "disabled" : ""}`}
    >
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <span>{labeltitle}</span>
        </Accordion.Header>

        <div className="accordion-action-button">
          <Link
            to="!#"
            className="btn btn-primary action-button"
            variant="primary"
            onClick={openSideBAr}
          >
            {t("components.buttons.add")} <Plus size="20px" />
          </Link>
        </div>

        <Accordion.Body className="overflow-auto">
          {partiesCodes != null && (
            <>
              {parties.map((party, i) => {
                let headers = [
                  t("forms.labels.general.full_name"),
                  t("forms.labels.general.full_address"),
                  t("forms.labels.general.phone"),
                  t("forms.labels.general.email"),
                  t("forms.labels.general.occupation"),
                ];

                const label = getPartyLabel(party.participation_code);

                return (
                  <div className="section-table-parties" key={i}>
                    <TableLabel label={label} />
                    <TableParties
                      headers={headers}
                      data={party}
                      hasActions={true}
                      openSideBAr={openSideBAr}
                      setSingleParty={setSingleParty}
                    />
                  </div>
                );
              })}
            </>
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default AccordionTable;
