import Info from "./Info";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Filters from "../../../Filters/Filters";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import FormMessage from "../../../Forms/FormMessage";

const Details = ({ data, formData, handleChange, errors }) => {
  const [t, i18n] = useTranslation("common");
  const options = [
    { value: "OPEN", label: "Open" },
    { value: "CLOSED", label: "Closed" },
    { value: "DELETED", label: "Deleted" },
    // Add more options as needed
  ];
  return (
    <Container fluid className="sidebar-info">
      <Row>
        <Col xs={12}>
          <div className="d-flex flex-md-nowrap flex-wrap align-items-start justify-content-md-between mb-40">
            <div className="title-wrapper order-md-0 order-1 mt-md-0 mt-3">
              <Form.Label htmlFor="title" className="visually-hidden">
                {`${t("forms.labels.placeholders.add_title")}`}
              </Form.Label>
              <Form.Control
                id="title"
                className="p-0 add-title h3 text-mainblue m-0"
                placeholder={`${t("forms.labels.placeholders.add_title")}...`}
                defaultValue={data.title ? data.title : ""}
                onChange={(e) => handleChange("title", e.target.value)}
                autoComplete="off"
              />
              <FormMessage
                message="Please add a title"
                error={errors.error_title}
              />
            </div>
            <Filters
              options={options}
              defaultVal={formData.status}
              controlId="formStatus"
              name="status"
              handleChange={handleChange}
            />
          </div>

          <Info info={data} handleChange={handleChange} errors={errors} />
        </Col>
      </Row>
    </Container>
  );
};

export default Details;
