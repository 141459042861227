import { isNotEmptyOrNullUndefined } from "./utils";
import Keycloak from "keycloak-js";
import { apiClient } from "../services/apiService";
import { UserModel } from "../models/UserModel";

let currentUser: UserModel | null = null;
let keycloak: Keycloak.KeycloakInstance;
const KEYCLOACK_URL = import.meta.env.VITE_ICANEPO_APP_KEYCLOACK_URL; // Your base URL from .env file
const KEYCLOACK_REALM = import.meta.env.VITE_ICANEPO_APP_REALM; // Your base URL from .env file
const KEYCLOACK_CLIENT_ID = import.meta.env.VITE_ICANEPO_APP_CLIENTID; // Your base URL from .env file
const KEYCLOAK_ADMIN_URL = import.meta.env.VITE_ICANEPO_APP_KEYCLOAK_ADMIN_URL;

/**
 * Fetch all users from Keycloak
 * @returns {Promise<any[]>} Array of users
 */
const getAllUsersFromKeycloak = async (): Promise<any[]> => {
  try {
    const accessToken = getToken(); // Get the admin access token, assumed to be obtained via Keycloak auth

    if (!accessToken) {
      throw new Error("No access token found");
    }
    const response = await apiClient.get(`${KEYCLOAK_ADMIN_URL}/users`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch users from Keycloak:", error);
    throw error;
  }
};


const fetchUsers = async () => {
  try {
    const users = await getAllUsersFromKeycloak();
    console.log("Users from Keycloak:", users);
  } catch (error) {
    console.error("Error fetching users:", error);
  }
};

export const authenticateUser = () => {
  try {
    // Check if the user has a valid access token
    const accessToken = localStorage.getItem("accessToken");
    if (isNotEmptyOrNullUndefined(accessToken)) {
      apiClient.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${accessToken}`;
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Authentication error:", error);
  }
};

// Keycloak initialization options
const initOptions = {
  url: KEYCLOACK_URL,
  realm: KEYCLOACK_REALM,
  clientId: KEYCLOACK_CLIENT_ID,
};

// Function to map Keycloak token to UserModel
const createUserModelFromKeycloak = (tokenParsed: any): UserModel => {
  return new UserModel({
    acr: tokenParsed.acr || "",
    allowed_origins: tokenParsed.allowed_origins || [],
    aud: tokenParsed.aud || "",
    auth_time: tokenParsed.auth_time || 0,
    azp: tokenParsed.azp || "",
    email: tokenParsed.email || "",
    email_verified: tokenParsed.email_verified || false,
    exp: tokenParsed.exp || 0,
    family_name: tokenParsed.family_name || "",
    given_name: tokenParsed.given_name || "",
    iat: tokenParsed.iat || 0,
    iss: tokenParsed.iss || "",
    jti: tokenParsed.jti || "",
    name: tokenParsed.name || "",
    nonce: tokenParsed.nonce || "",
    preferred_username: tokenParsed.preferred_username || "",
    realm_access: { roles: tokenParsed.realm_access?.roles || [] },
    resource_access: {
      account: { roles: tokenParsed.resource_access?.account?.roles || [] },
    },
    scope: tokenParsed.scope || "",
    session_state: tokenParsed.session_state || "",
    sid: tokenParsed.sid || "",
    sub: tokenParsed.sub || "",
    typ: tokenParsed.typ || "",
  });
};

const initKeycloak = async () => {
  if (!keycloak) {
    keycloak = new Keycloak(initOptions);
    try {
      const auth = await keycloak.init({
        onLoad: "login-required", // Supported values: 'check-sso', 'login-required'
        checkLoginIframe: true,
        pkceMethod: "S256",
      });
      console.log("has auth", auth);
      if (!auth) {
        window.location.reload();
      } else {
        console.info("Authenticated");
        console.log("auth", auth);
        console.log("Keycloak", keycloak);
        // Store token and userId in localStorage
        localStorage.setItem("accessToken", keycloak.token);
        localStorage.setItem("userId", keycloak.tokenParsed.sub); 
        apiClient.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${keycloak.token}`;
      }

      // Set current user from Keycloak token
      currentUser = createUserModelFromKeycloak(keycloak.tokenParsed);

      // Handle token expiration
      keycloak.onTokenExpired = async () => {
        try {
          const refreshed = await keycloak.updateToken(30);
          if (refreshed) {
            console.log("Token refreshed");
            localStorage.setItem("accessToken", keycloak.token);
            localStorage.setItem("userId", keycloak.tokenParsed.sub);
          } else {
            console.warn(
              "Token not refreshed, valid for only " +
                keycloak.tokenParsed.exp +
                " seconds"
            );
          }
        } catch (error) {
          console.error("Failed to refresh token", error);
        }
      };
    } catch (error) {
      console.error("Authentication Failed", error);

      // Handle specific error: Timeout or iframe issues
      if (
        error?.error ===
        "Timeout when waiting for 3rd party check iframe message."
      ) {
        // Redirect or show user-friendly error
        console.error("Keycloak iframe timeout error occurred.");
        return { error: "iframeTimeout" }; // Return identifiable error type
      }

      // Handle general errors
      return { error: "authFailed", details: error }; // Include details for debugging
    }
  }
};


const getCurrentUser = (): UserModel | null => {
  return currentUser;
};

const doLogout = async () => {
  try {
    if (!keycloak || typeof keycloak.logout !== "function") {
      throw new Error(
        "Keycloak is not initialized or logout method is not available"
      );
    }

    await keycloak.logout();
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userId"); // Remove userId on logout
    delete apiClient.defaults.headers.common["Authorization"];
  } catch (error) {
    console.error("Logout error:", error);
  }
};
const isAuthenticated = () => {
  if (keycloak) {
    return keycloak.authenticated;
  }
  return false;
};
const getToken = () => keycloak.token;
const getUserId = () =>
  keycloak.tokenParsed?.sub || localStorage.getItem("userId"); // Get userId
const getUserName = () => {
  if (keycloak && keycloak.tokenParsed) {
    return (
      keycloak.tokenParsed.name ||
      keycloak.tokenParsed.preferred_username ||
      "Unknown User"
    );
  }
  return null; // or return a default value
};

export {
  initKeycloak,
  doLogout,
  getToken,
  getUserId,
  getUserName,
  isAuthenticated,
  getCurrentUser,
  fetchUsers,
};
