import React, { useState, useEffect, useMemo } from "react";
import Table from "react-bootstrap/Table";
import Description from "./Description/Description";
import { Dropdown, ButtonGroup, Pagination } from "react-bootstrap";
import { ThreeDots } from "react-bootstrap-icons";
import Badge from "react-bootstrap/Badge";
import { CovertTableDates, IsDateExpired } from "../../../utils/func";
import Thead from "./Layout/Thead";
import "./TableLayout.scss";
import { useTranslation } from "react-i18next";

const TableLayoutTasks = ({
  sideNavExpanded,
  setSideNavExpanded,
  setRow,
  getRow,
  tasks,
  setIsOpen,
  isOpen,
}) => {
  const [activeRow, setActiveRow] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [t, i18n] = useTranslation("common");
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const tasksPerPage = 10; // Adjust as needed
  const [headers, setHeaders] = useState(false);

  useEffect(() => {
    setHeaders([
      {
        label: t("app.general.description"),
        key: "description",
        sortable: false,
      },
      {
        label: t("app.general.casenumber"),
        key: "caseNumber",
        sortable: true,
      },
      {
        label: t("app.general.department"),
        key: "department",
        sortable: true,
      },
      {
        label: t("app.general.claimant"),
        key: "claimant",
        sortable: true,
      },
      {
        label: t("app.general.defendant"),
        key: "defendant",
        sortable: true,
      },
      {
        label: t("app.general.amount"),
        key: "amount",
        sortable: true,
      },
      {
        label: t("app.general.date"),
        key: "duedate_at",
        sortable: false,
      },
      {
        label: t("app.general.status"),
        key: "status",
        sortable: false,
      },
    ]);
  }, [i18n.language]);

  const handleAction = (action) => {
    // Perform action based on selected dropdown item
    console.log("Action:", action);
  };

  // Sorting logic
  const sortedTasks = useMemo(() => {
    const getValue = (task, key) => {
      // Extract value based on the key, accounting for nested keys
      switch (key) {
        case "caseNumber":
          return task.related_case?.caseNumber || "";
        case "department":
          return task.related_case?.department || "";
        case "claimant":
          return task.related_case?.claimant || "";
        case "defendant":
          return task.related_case?.defendant || "";
        case "amount":
          // Convert amount to a number for proper sorting, removing non-numeric characters
          return (
            parseFloat(task.related_case?.amount?.replace(/[^\d.-]/g, "")) || 0
          );
        default:
          return task[key] || ""; // Default to top-level field
      }
    };

    let sortableTasks = [...tasks];
    if (sortConfig.key !== null) {
      sortableTasks.sort((a, b) => {
        const aValue = getValue(a, sortConfig.key);
        const bValue = getValue(b, sortConfig.key);

        if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
        if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
        return 0;
      });
    }
    return sortableTasks;
  }, [tasks, sortConfig]);

  // Pagination logic
  const indexOfLastTask = currentPage * tasksPerPage;
  const indexOfFirstTask = indexOfLastTask - tasksPerPage;
  const currentTasks = sortedTasks.slice(indexOfFirstTask, indexOfLastTask);

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const handlePagination = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      {headers && (
        <div className="table-responsive border-3 border-top table-shadow">
          <Table hover>
            <Thead
              headers={headers}
              hasActions={true}
              hascolSpan2={true}
              onSort={handleSort}
              sortConfig={sortConfig}
            />
            <tbody>
              {currentTasks.length > 0 ? (
                currentTasks.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    className={activeRow === rowIndex ? "table-active" : ""}
                    onClick={(e) => {
                      setActiveRow(rowIndex);
                      if (!e.target.classList.contains("actions-field")) {
                        if (
                          getRow == null ||
                          (getRow != null && getRow != row.id)
                        ) {
                          setSideNavExpanded(true);
                          setIsOpen(true);
                        } else {
                          setSideNavExpanded(false);
                          setIsOpen(false);
                        }
                        setRow(row.id);
                      }
                    }}
                    expanded={sideNavExpanded.toString()}
                  >
                    <td colSpan="2">
                      <Description data={row} />
                    </td>
                    <td className="colspan1" colSpan="2">
                      {row.related_case && row.related_case.caseNumber ? (
                        <>{row.related_case.caseNumber}</>
                      ) : (
                        <Badge bg="softred" text="darkred">
                          {`${t("forms.labels.general.unassigned")}`}
                        </Badge>
                      )}
                    </td>
                    <td className="colspan1" colSpan="1">
                      {row.related_case?.department || "N/A"}
                    </td>
                    <td className="colspan1" colSpan="1">
                      {row.related_case?.claimant || "N/A"}
                    </td>
                    <td className="colspan1" colSpan="1">
                      {row.related_case?.defendant || "N/A"}
                    </td>
                    <td className="colspan1" colSpan="1">
                      {row.related_case?.amount || "N/A"}
                    </td>
                    <td className="colspan1" colSpan="1">
                      <div className="text-nowrap">
                        {`${t("app.general.created")}:`}{" "}
                        <CovertTableDates date={row.created_at} />
                      </div>
                      <div className="text-nowrap">
                        <span>{`${t("app.general.due")}:`}</span>
                        {row.duedate_at != null ? (
                          <span
                            className={`ps-1 fw-medium text-nowrap ${
                              IsDateExpired({ date: row.duedate_at })
                                ? "text-danger"
                                : ""
                            }`}
                          >
                            <CovertTableDates date={row.duedate_at} />
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                    </td>
                    <td className="colspan1" colSpan="1">
                      {row.status == "OPEN" ? (
                        <Badge bg="softgreen" text="darkgreen">
                          {row.status}
                        </Badge>
                      ) : (
                        <Badge bg="softyellow" text="darkyellow">
                          {row.status}
                        </Badge>
                      )}
                    </td>
                    <td className="colspan1 " colSpan="1">
                      <Dropdown as={ButtonGroup}>
                        <Dropdown.Toggle
                          variant="link"
                          id="dropdown-basic"
                          className="py-0 actions-field"
                        >
                          <ThreeDots />
                          <span className="visually-hidden">Actions</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => handleAction("Edit")}>
                            {t("components.buttons.edit")}
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleAction("Delete")}>
                            {t("components.buttons.delete")}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={headers.length + 2} className="text-center">
                    No tasks found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <div className="d-flex justify-content-end pe-2 mt-3">
            <Pagination>
              <Pagination.First onClick={() => handlePagination(1)} />
              <Pagination.Prev
                onClick={() => handlePagination(Math.max(1, currentPage - 1))}
              />
              {[...Array(Math.ceil(tasks.length / tasksPerPage)).keys()].map(
                (x) => (
                  <Pagination.Item
                    key={x + 1}
                    active={x + 1 === currentPage}
                    onClick={() => handlePagination(x + 1)}
                  >
                    {x + 1}
                  </Pagination.Item>
                )
              )}
              <Pagination.Next
                onClick={() =>
                  handlePagination(
                    Math.min(
                      currentPage + 1,
                      Math.ceil(tasks.length / tasksPerPage)
                    )
                  )
                }
              />
              <Pagination.Last
                onClick={() =>
                  handlePagination(Math.ceil(tasks.length / tasksPerPage))
                }
              />
            </Pagination>
          </div>
        </div>
      )}
    </>
  );
};

export default TableLayoutTasks;
