import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import { Link, useLocation, Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import { User, Notification } from "react-iconly";
import logo from "../../assets/logo.svg"; // Keep your existing logo
import { getToken, doLogout, getUserName } from "../../utils/keykloak";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import { AiOutlineMenu } from "react-icons/ai"; // For burger menu icon
import "./Header.scss";

function Header() {
  const [authenticated, setAuthenticated] = useState(null);
  const [userName, setUserName] = useState(null);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const location = useLocation();
  const { t } = useTranslation("common");

  const handleLogout = async () => {
    try {
      await doLogout();
    } catch (error) {
      console.error("Logout failed", error);
    }
  };

  useEffect(() => {
    setUserName(getUserName());
  }, []);

  useEffect(() => {
    const loggedInUser = getToken();
    if (loggedInUser) {
      setAuthenticated(loggedInUser);
    }
  }, []);

  const handleClose = () => setShowOffcanvas(false);
  const handleShow = () => setShowOffcanvas(true);

  const getActiveClass = (path) =>
    location.pathname.includes(path) ? "active" : "";

  if (!authenticated) {
    return <Outlet />;
  } else {
    return (
      <>
        <header className="dashboard-header">
          <Navbar
            expand="lg"
            variant="dark"
            className="navbar w-100 navbar-expand-lg center-nav transparent navbar-dark"
          >
            <Container
              fluid
              className="flex-lg-row flex-nowrap align-items-center"
            >
              <div className="navbar-brand me-0 w-100">
                <Link to="/tasks">
                  <img
                    src={logo}
                    width="74"
                    height="74"
                    className="d-inline-block align-top"
                    alt="ICANEPO Logo"
                  />
                </Link>
              </div>

              <Navbar.Offcanvas
                id="offcanvasNavbar"
                aria-labelledby="offcanvasNavbarLabel" // Reference to the new label
                show={showOffcanvas}
                onHide={handleClose}
              >
                <div id="offcanvasNavbarLabel" className="visually-hidden">
                  Main Navigation
                </div>
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title className="visually-hidden">
                    Main Navigation
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="flex-column h-100">
                    <ul className="navbar-nav justify-content-center flex-column flex-lg-row">
                      <li className="nav-item px-lg-0 px-1">
                        <Nav.Link
                          as={Link}
                          to="/tasks"
                          className={getActiveClass("/tasks")}
                        >
                          {t("components.navigation.tasks")}
                        </Nav.Link>
                      </li>
                      <li className="nav-item px-lg-0 px-1">
                        <Nav.Link
                          as={Link}
                          to="/cases"
                          className={getActiveClass("/cases")}
                        >
                          {t("components.navigation.cases")}
                        </Nav.Link>
                      </li>
                      <li className="nav-item px-lg-0 px-1">
                        <Nav.Link
                          as={Link}
                          to="/data-entry"
                          className={getActiveClass("/data-entry")}
                        >
                          {t("components.navigation.dataentry")}
                        </Nav.Link>
                      </li>
                    </ul>
                  </Nav>
                  <div className="d-lg-none lang-switcher">
                    <LanguageSelector />
                  </div>
                </Offcanvas.Body>
              </Navbar.Offcanvas>

              {/* Navbar Other Content (Notifications & Profile) */}
              <div className="navbar-other w-100 d-flex  ms-auto">
                <Nav className="navbar-nav flex-row align-items-center ms-auto">
                  <div className="d-lg-block d-none lang-switcher">
                    <LanguageSelector />
                  </div>
                  <Nav.Link href="#!" className="notification">
                    <Notification filled="true" width={30} height={30} />
                    <span className="visually-hidden">Notifications</span>
                  </Nav.Link>
                  <Dropdown>
                    <DropdownToggle className="notificatons bg-transparent p-0 border-0 d-flex align-items-center">
                      <User
                        className="ms-0"
                        filled="true"
                        label="User"
                        width={30}
                        height={30}
                      />
                      <span className="text-nowrap ms-2 d-none d-md-block">
                        {t("app.general.welcome")}, {userName || ""}
                      </span>
                      <i className="mdi mdi-chevron-down d-none d-sm-inline-block align-middle"></i>
                    </DropdownToggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={handleLogout}>
                      {t("app.general.logout")}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav>
              </div>
              <Navbar.Toggle
                aria-controls="offcanvasNavbar"
                onClick={handleShow}
                className="d-lg-none"
              >
                <AiOutlineMenu size={25} />
              </Navbar.Toggle>
            </Container>
          </Navbar>
        </header>
        <Outlet />
      </>
    );
  }
}

export default Header;
