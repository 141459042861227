import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useEffect } from "react";

import Repeater from "../../../Repeater/Repeater";
import Card from "../../../Card/Card";
import langcode from "../../../../../public/demo/lang_codes.json";
import formbcode from "../../../../../public/demo/formb_codes.json";
import DueDate from "../DueDate";
import FormCardHeader from "../CardHeader";
import { useTranslation } from "react-i18next";
const FormB = ({
  id,
  onInputChange,
  langSections,
  setLangSections,
  formCodesSections,
  setFormCodesSections,
  langCodes,
  formCodes,
  setlangCodes,
  setFormCodes,
  formData,
  addSection,
  removeSection,
  handleSelectChange,
  handleTextChange,
}) => {
  const [t, i18n] = useTranslation("common");
  useEffect(() => {
    setlangCodes(langcode);
    setFormCodes(formbcode);
  }, []);

  const formBSection = (formData) => {
    return (
      <DueDate
        title={t("forms.labels.formB.notice")}
        onInputChange={onInputChange}
        value={formData && formData.due_date ? formData.due_date : null}
        content={[
          t("forms.labels.formB.language_instructions_1"),
          t("forms.labels.formB.language_instructions_2"),
        ]}
      />
    );
  };

  const FormBRepeater = ({
    options,
    sections,
    handleTextChange,
    handleSelectChange,
    setSection,
    controlId,
    textname,
    selecteplaceholder,
    textplaceholder,
    type,
  }) => {
    return (
      <Container fluid>
        <Row>
          <Col xs={12}>
            <Repeater
              options={options}
              sections={sections}
              handleTextChange={handleTextChange}
              handleSelectChange={handleSelectChange}
              controlId={controlId}
              textname={textname}
              selecteplaceholder={selecteplaceholder}
              textplaceholder={textplaceholder}
              removeSection={(id) =>
                removeSection(id, setSection, sections, type)
              }
            />
          </Col>
        </Row>
      </Container>
    );
  };

  return (
    <>
      <div className="section">
        <Card body={formBSection(formData)} />
      </div>
      <div className="section">
        {langCodes != null && (
          <Card
            header={
              <FormCardHeader
                title={t("forms.labels.formB.language_error")}
                addAction={() => addSection(setLangSections, langSections)}
              />
            }
            body={
              <FormBRepeater
                options={langCodes}
                sections={langSections}
                type="lang_code"
                handleTextChange={(id, event) =>
                  handleTextChange(
                    "lang_code",
                    id,
                    event,
                    setLangSections,
                    langSections
                  )
                }
                handleSelectChange={(id, event) =>
                  handleSelectChange(
                    "lang_code",
                    id,
                    event,
                    setLangSections,
                    langSections
                  )
                }
                setSection={setLangSections}
                controlId="lang_"
                selecteplaceholder={t("forms.labels.select.language_code")}
                textRequired={false}
                textname="code_"
                textplaceholder={`${t(
                  "forms.labels.placeholders.code_specification"
                )} 20`}
                removeSection={(id) =>
                  removeSection(id, setLangSections, langSections, "lang_code")
                }
              />
            }
          />
        )}
      </div>
      <div className="section">
        {formCodes != null && (
          <Card
            header={
              <FormCardHeader
                title={t("forms.labels.formB.rectification_items")}
                addAction={() =>
                  addSection(setFormCodesSections, formCodesSections)
                }
              />
            }
            body={
              <FormBRepeater
                options={formCodes}
                sections={formCodesSections}
                type="form_code"
                handleTextChange={(id, event) =>
                  handleTextChange(
                    "form_code",
                    id,
                    event,
                    setFormCodesSections,
                    formCodesSections
                  )
                }
                handleSelectChange={(id, event) =>
                  handleSelectChange(
                    "form_code",
                    id,
                    event,
                    setFormCodesSections,
                    formCodesSections
                  )
                }
                setSection={setFormCodesSections}
                controlId="formcode_"
                selecteplaceholder={t("forms.labels.select.code")}
                textname="specification_"
                textRequired={false}
                textplaceholder={t("forms.labels.placeholders.specify")}
                removeSection={(id) =>
                  removeSection(
                    id,
                    setFormCodesSections,
                    formCodesSections,
                    "formcode_"
                  )
                }
              />
            }
          />
        )}
      </div>
    </>
  );
};
export default FormB;
