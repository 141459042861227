import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const SubmitForm = ({ id, handleSaveAsDraft, handleCancel }) => {
  const [t, i18n] = useTranslation("common");
  return (
    <div className="submit-section">
      <Container fluid>
        <Row className="justify-content-end">
          <Col xs="auto">
            <Button
              variant="secondary"
              onClick={handleCancel}
              className="ml-2"
              disabled={false}
            >
              {t("components.buttons.cancel")}
            </Button>
          </Col>
          <Col xs="auto">
            <Dropdown>
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                {t("components.buttons.saveas")}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={handleSaveAsDraft}>
                  {t("app.general.draft")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col xs="auto">
            <Button variant="primary" type="submit">
              {t("components.buttons.finalize")}
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SubmitForm;
