import moment from "moment";
export const TimestampConverter = ({ time }) => {
  // Convert timestamp to a Date object
  const date = new Date(time);

  // Extract individual components (year, month, day, hour, minute, second)
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hour = String(date.getHours()).padStart(2, "0");
  const minute = String(date.getMinutes()).padStart(2, "0");
  const second = String(date.getSeconds()).padStart(2, "0");

  // Format the date in the desired format
  const formattedDate = `${year}-${month}-${day} ${hour}:${minute}:${second}`;

  return formattedDate;
};

export const CovertDates = (props) => {
  let currentDate = moment(props.date).format("DD/MM/YYYY");
  return currentDate;
};

export const CovertCommentsDates = (props) => {
  let currentDate = moment(props.date).format("MMM DD YYYY, h:mmA");
  return currentDate;
};

export const CovertTableDates = (props) => {
  let currentDate = moment(props.date).format("MMM DD");
  return currentDate;
};

export const IsDateExpired = (props) => {
  let currentDate = moment(props.date);
  let today = moment();
  let isDateLessThanToday = moment(currentDate).isBefore(today);
  return isDateLessThanToday;
};

export const CovertTime = (props) => {
  let currentDate = moment(props.date).format("MMMM Do YYYY, h:mm:ss a");
  return props.date;
};

export const ExtractInitials = (props) => {
  if (props.fullName) {
    const names = props.fullName.split(" ");
    const firstNameInitial = names[0].charAt(0);
    const lastNameInitial =
      names.length > 1 ? names[names.length - 1].charAt(0) : "";
    return `${firstNameInitial}${lastNameInitial}`;
  }
};

export const HumanizeDateTime = ({ date,t }) => {
  const currentDate = moment();
  const datetimeDiff = currentDate.diff(moment(date), "minutes");
  const hoursDiff = currentDate.diff(moment(date), "hours");
  const daysDiff = currentDate.diff(moment(date), "days");
  const monthsDiff = currentDate.diff(moment(date), "months");
  const yearsDiff = currentDate.diff(moment(date), "years");

  if (datetimeDiff < 1) {
    return  ` ${t("app.general.moment_ago")}`;
  } else if (datetimeDiff < 60) {
    return ` ${datetimeDiff} ${t("app.general.minutes_ago")}`;
  } else if (hoursDiff < 24) {
    return ` ${hoursDiff} ${t("app.general.hours_ago")}`;
  } else if (daysDiff < 30) {
    return ` ${daysDiff} ${t("app.general.days_ago")}`;
  } else if (monthsDiff < 12) {
    return ` ${monthsDiff} ${t("app.general.months_ago")}`;
  } else {
    return ` ${yearsDiff} ${t("app.general.years_ago")}`;
  }
};

export const FormatFileSize = ({ sizeInBytes }) => {
  if (sizeInBytes === 0) return "0 Bytes";

  const sizes = ["Bytes", "KB", "MB", "GB"];
  const i = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
  const size = parseFloat((sizeInBytes / Math.pow(1024, i)).toFixed(2));

  return `${size} ${sizes[i]}`;
};

export const FormInfo = (id, t) => {
  let info = {};
  switch (id) {
    case 0:
      info = {
        title: t("forms.headers.formA"),
        content: t("forms.headers.forma_article"),
      };
      break;
    case 1:
      info = {
        title: t("forms.headers.formB"),
        content: t("forms.headers.formb_article"),
      };
      break;
    case 2:
      info = {
        title: t("forms.headers.formC"),
        content: t("forms.headers.formc_article"),
      };
      break;
    case 3:
      info = {
        title: t("forms.headers.formD"),
        content: t("forms.headers.formd_article"),
      };
      break;
    case 4:
      info = {
        title: t("forms.headers.formE"),
        content:t("forms.headers.forme_article"),
      };
      break;
    case 5:
      info = {
        title: t("forms.headers.formG"),
        content:t("forms.headers.formg_article"),
      };
      break;
    default:
      return;
  }
  return info;
};
