import { Button, Container, Row, Col } from "react-bootstrap"; // Import Bootstrap components
import { useTranslation } from "react-i18next";
const ErrorPage = () => {
  const [t, i18n] = useTranslation("common");
  const handleRetry = () => {
    // Logic to retry the authentication process or refresh the page
    //window.location.reload(); // This reloads the page, retrying the authentication process
    window.location.href = "/";
  };

  return (
    <Container
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <Row className="text-center">
        <Col>
          <h1 className="text-primary">{t("app.errorpage.title")}</h1>
          <p className="text-info">{t("app.errorpage.message")}</p>
          <p className="text-info">{t("app.errorpage.apology")}</p>
          <Button variant="primary" onClick={handleRetry} className="mx-auto">
            {t("components.buttons.try_again")}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default ErrorPage;
