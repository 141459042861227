import { useState, useRef, useEffect } from "react";
import i18next from "i18next";
import "./Translations.scss"

// Store used translation keys
let usedTranslationKeys = [];

// Override the `i18next.t` function to detect used keys and strings
const originalT = i18next.t;
i18next.t = function (key, options) {
  // Add the key to the used translation keys and store the corresponding string
  if (!usedTranslationKeys.some((entry) => entry.key === key)) {
    usedTranslationKeys.push({ key, string: originalT(key, options) });
  }

  // Call the original `t` function to get the translation
  return originalT(key, options);
};

// Translation Popup Component
const TranslationPopup = ({ onFindClick }) => {
  return (
    <div className="translation-popup">
      <h3>Used Translation Keys and Strings</h3>
      <table className="table table-responsive">
        <thead>
          <tr>
            <th>Key</th>
            <th>String</th>
            <th>Find</th>
          </tr>
        </thead>
        <tbody>
          {usedTranslationKeys.map((entry, index) => (
            <tr key={index}>
              <td>{entry.key}</td>
              <td>{entry.string}</td>
              <td>
                <button onClick={() => onFindClick(entry.key)}>
                  Find
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

// Main Component to Toggle Translation Mode
const Translations = () => {
  const [translationMode, setTranslationMode] = useState(false);
  const textRefs = useRef({}); // to store references to each text element

  const toggleTranslationMode = () => {
    setTranslationMode(!translationMode);
  };

  // Handle finding and highlighting the text
  const handleFindClick = (key) => {
    const textElement = textRefs.current[key];
    console.log(textRefs.current); // Check if refs are populated
    if (textElement) {
      textElement.scrollIntoView({ behavior: "smooth", block: "center" });
      textElement.style.backgroundColor = "yellow"; // highlight the text
    }
  };

  // Render translation strings with refs
  return (
    <div>
      {/* Button to toggle translation mode */}
      <button className="btn btn-primary toggle-btn" onClick={toggleTranslationMode}>
        {translationMode ? "Exit Translation Mode" : "Enter Translation Mode"}
      </button>

      {/* Floating popup with translations */}
      {translationMode && <TranslationPopup onFindClick={handleFindClick} />}
    </div>
  );
};

export default Translations;
