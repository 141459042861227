import Table from "react-bootstrap/Table";
import SelectForm from "../../Forms/SelectForm";
import FormText from "../../Forms/FormText";
import IconComponent from "../../IconComponent/IconComponent";
import CalendarComponent from "../../Calendar/Calendar";
import { Calendar } from "react-iconly";
import { useTranslation } from "react-i18next";
const TableRepeater = ({ curencies, handleDefendantChange, section }) => {
  const [t, i18n] = useTranslation("common");
  return (
    <div className="table-responsive">
      <Table id={`table_${section.id}`} className="mb-0">
        <thead>
          <tr>
            <th>{t("forms.labels.formE.cost_type")}</th>
            <th>{t("forms.labels.formE.currency")}</th>
            <th>{t("forms.labels.placeholders.ammount")}</th>
            <th>{t("app.general.date")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="colspan1">
              <span className="h3 fw-semibold">{t("forms.labels.formE.principal_amount")}</span>
            </td>
            <td className="colspan1">
              <SelectForm
                options={curencies}
                defaultVal={section.principal.currency}
                controlId={`principal_${section.id}`}
                name={`principal_${section.id}`}
                placeholder={t("forms.labels.select.currency")}
                onChange={(event) =>
                  handleDefendantChange(
                    section.id,
                    event.value,
                    "principal",
                    "currency"
                  )
                }
              />
            </td>
            <td className="colspan1">
              <FormText
                type="text"
                name={`principal_amount_${section.id}`}
                value={section.principal.amount}
                isRequired={false}
                label={t("forms.labels.select.currency")}
                hideLabel={true}
                onChange={(event) =>
                  handleDefendantChange(
                    section.id,
                    event.target.value,
                    "principal",
                    "amount"
                  )
                }
              />
            </td>
            <td className="colspan1">
              <div className="d-flex align-items-center ">
                <IconComponent
                  icon={<Calendar label="Calendar" primaryColor="#264687" />}
                  text=""
                />
                <CalendarComponent
                label="principal_date"
                  handleChange={(date, value) =>
                    handleDefendantChange(
                      section.id,
                      value,
                      "principal",
                      "date"
                    )
                  }
                  date={section.principal.date}
                  error={false}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td className="colspan1">
              <span className="h3 fw-semibold">{t("forms.labels.formE.interest_details")}</span>
            </td>
            <td className="colspan1">
              <SelectForm
                options={curencies}
                defaultVal={section.interest.currency}
                controlId={`interest_${section.id}`}
                name={`interest_${section.id}`}
                placeholder={t("forms.labels.select.currency")}
                onChange={(event) =>
                  handleDefendantChange(
                    section.id,
                    event.value,
                    "interest",
                    "currency"
                  )
                }
              />
            </td>
            <td className="colspan1">
              <FormText
                type="text"
                name={`interest_amount_${section.id}`}
                value={section.interest.amount}
                isRequired={false}
                label={t("forms.labels.select.currency")}
                hideLabel={true}
                onChange={(event) =>
                  handleDefendantChange(
                    section.id,
                    event.target.value,
                    "interest",
                    "amount"
                  )
                }
              />
            </td>
            <td className="colspan1">
              <div className="d-flex align-items-center ">
                <IconComponent
                  icon={<Calendar label="Calendar" primaryColor="#264687" />}
                  text=""
                />
                <CalendarComponent
                label="interest_date"
                  handleChange={(date, value) =>
                    handleDefendantChange(section.id, value, "interest", "date")
                  }
                  date={section.interest.date}
                  error={false}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td className="colspan1">
              <span className="h3 fw-semibold">{t("forms.labels.formE.contractual_penalties")}</span>
            </td>
            <td className="colspan1">
              <SelectForm
                options={curencies}
                defaultVal={section.penalties.currency}
                controlId={`penalties_${section.id}`}
                name={`penalties_${section.id}`}
                placeholder={t("forms.labels.select.currency")}
                onChange={(event) =>
                  handleDefendantChange(
                    section.id,
                    event.value,
                    "penalties",
                    "currency"
                  )
                }
              />
            </td>
            <td className="colspan1">
              <FormText
                type="text"
                name={`penalties_amount_${section.id}`}
                value={section.penalties.amount}
                isRequired={false}
                hideLabel={true}
                label={t("forms.labels.select.currency")}
                onChange={(event) =>
                  handleDefendantChange(
                    section.id,
                    event.target.value,
                    "penalties",
                    "amount"
                  )
                }
              />
            </td>
            <td className="colspan1">
              <div className="d-flex align-items-center ">
                <IconComponent
                  icon={<Calendar label="Calendar" primaryColor="#264687" />}
                  text=""
                />
                <CalendarComponent
                label="penalties_date"
                  handleChange={(date, value) =>
                    handleDefendantChange(
                      section.id,
                      value,
                      "penalties",
                      "date"
                    )
                  }
                  date={section.penalties.date}
                  error={false}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td className="colspan1">
              <span className="h3 fw-semibold">Costs</span>
            </td>
            <td className="colspan1">
              <SelectForm
                options={curencies}
                defaultVal={section.costs.currency}
                controlId={`costs_${section.id}`}
                name={`costs_${section.id}`}
                placeholder={t("forms.labels.select.currency")}
                onChange={(event) =>
                  handleDefendantChange(
                    section.id,
                    event.value,
                    "costs",
                    "currency"
                  )
                }
              />
            </td>
            <td className="colspan1">
              <FormText
                type="text"
                name={`costs_amount_${section.id}`}
                value={section.costs.amount}
                isRequired={false}
                hideLabel={true}
                label={t("forms.labels.select.currency")}
                onChange={(event) =>
                  handleDefendantChange(
                    section.id,
                    event.target.value,
                    "costs",
                    "amount"
                  )
                }
              />
            </td>
            <td className="colspan1">
              <div className="d-flex align-items-center ">
                <IconComponent
                  icon={<Calendar label="Calendar" primaryColor="#264687" />}
                  text=""
                />
                <CalendarComponent
                label="costs_date"
                  handleChange={(date, value) =>
                    handleDefendantChange(section.id, value, "costs", "date")
                  }
                  date={section.costs.date}
                  error={false}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td className="colspan1">
              <span className="h3 fw-semibold">{t("forms.labels.formE.total_amount")}</span>
            </td>
            <td className="colspan1">
              <SelectForm
                options={curencies}
                defaultVal={section.total.currency}
                controlId={`total_${section.id}`}
                name={`total_${section.id}`}
                placeholder={t("forms.labels.select.currency")}
                onChange={(event) =>
                  handleDefendantChange(
                    section.id,
                    event.value,
                    "total",
                    "currency"
                  )
                }
              />
            </td>
            <td className="colspan1">
              <FormText
                type="text"
                name={`total_amount_${section.id}`}
                value={section.total.amount}
                isRequired={false}
                label={t("forms.labels.select.currency")}
                hideLabel={true}
                onChange={(event) =>
                  handleDefendantChange(
                    section.id,
                    event.target.value,
                    "total",
                    "amount"
                  )
                }
              />
            </td>
            <td className="colspan1">
              <div className="d-flex align-items-center ">
                <IconComponent
                  icon={<Calendar label="Calendar" primaryColor="#264687" />}
                  text=""
                />
                <CalendarComponent
                label="total_date"
                  handleChange={(date, value) =>
                    handleDefendantChange(section.id, value, "total", "date")
                  }
                  date={section.total.date}
                  error={false}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default TableRepeater;
