import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useEffect, useState, useRef } from "react";
import { Plus, X } from "react-bootstrap-icons";
import { Calendar } from "react-iconly";
import AccordionCard from "../../../AccordionCard/AccordionCard";
import { Button } from "react-bootstrap";
import groundscode from "../../../../../public/demo/grounds_codes.json";
import bankcode from "../../../../../public/demo/bank_codes.json";
import currencycode from "../../../../../public/demo/currency_codes.json";
import bordercode from "../../../../../public/demo/borders_codes.json";
import code1code from "../../../../../public/demo/code1_codes.json";
import code2code from "../../../../../public/demo/code2_codes.json";
import code3code from "../../../../../public/demo/code3_codes.json";
import costscode from "../../../../../public/demo/costs_codes.json";
import evidencecode from "../../../../../public/demo/evidence_codes.json";
import appendix1code from "../../../../../public/demo/appendix1_codes.json";
import appendix2code from "../../../../../public/demo/appendix2_codes.json";
import interestcode from "../../../../../public/demo/interest_codes.json";
import interestlettercode from "../../../../../public/demo/interestletter_codes.json";
import claimantcode from "../../../../../public/demo/claimant_codes.json";
import paymentcode from "../../../../../public/demo/payment_codes.json";
import SelectForm from "../../../Forms/SelectForm";
import FormText from "../../../Forms/FormText";
import FormTextArea from "../../../Forms/FormTextArea";
import RadiosForm from "../../../Forms/RadiosForm";
import CalendarComponent from "../../../Calendar/Calendar";
import { Form } from "react-bootstrap";
import SimpleMDE from "react-simplemde-editor";
import Card from "../../../Card/Card";
import IconComponent from "../../../IconComponent/IconComponent";
import CheckboxForm from "../../../Forms/CheckboxForm";
import { useTranslation } from "react-i18next";
const FormA = ({
  id,
  editorValue,
  setEditorValue,
  groundCodes,
  setGroundCodes,
  groundSections,
  setGroundSections,
  evidenceCodes,
  setEvidenceCodes,
  bordersCodes,
  interestLetterCodes,
  costsCodes,
  setcostsCodes,
  setInterestLetterCodes,
  setBordersCodes,
  handleTextChange,
  handleSelectChange,
  claimantCodes,
  interestCodes,
  setInterestCodes,
  setCode1Codes,
  code1Codes,
  setCode2Codes,
  code2Codes,
  setCode3Codes,
  code3Codes,
  setClaimantCodes,
  bankCodes,
  setBankCodes,
  bankDetails,
  setBankDetails,
  currencyCodes,
  setCurrencyCodes,
  principalDetails,
  setPrincipalDetails,
  setPrincipalSections,
  principalSections,
  removeSection,
  addNewSection,
  handleArrayTextChange,
  setInterestSections,
  interestSections,
  penaltiesSections,
  setPenaltiesSections,
  setCostsSections,
  costsSections,
  setEvidencesSections,
  evidencesSections,
  setAdditionalStatementsSections,
  additionalStatementsSections,
  appendix1Sections,
  setAppendix1Sections,
  appendix2Sections,
  setAppendix2Sections,
  crossBorderSections,
  setCrossBorderSections,
  paymentCodes,
  setPaymentCodes,
  appendix1Codes,
  appendix2Codes,
  setAppendix1Codes,
  setAppendix2Codes,
}) => {
  const [t, i18n] = useTranslation("common");
  useEffect(() => {
    setGroundCodes(groundscode);
    setClaimantCodes(claimantcode);
    setBankCodes(bankcode);
    setCurrencyCodes(currencycode);
    setPaymentCodes(paymentcode);
    setBordersCodes(bordercode);
    setCode1Codes(code1code);
    setCode2Codes(code2code);
    setCode3Codes(code3code);
    setInterestCodes(interestcode);
    setInterestLetterCodes(interestlettercode);
    setcostsCodes(costscode);
    setEvidenceCodes(evidencecode);
    setAppendix1Codes(appendix1code);
    setAppendix2Codes(appendix2code);
  }, []);

  const FormARepeaterLayout = ({
    options,
    handleTextChange,
    handleSelectChange,
    controlId,
    textname,
    selecteplaceholder,
    textplaceholder,
    textRequired,
    hasExplanation,
    textContent,
    defaultVal,
    value,
  }) => {
    return (
      <>
        <Container fluid className="mb-3">
          <Row>
            <Col xs={12}>
              <Row className="row-hover align-items-start">
                <Col md={6}>
                  <SelectForm
                    options={options}
                    defaultVal={defaultVal}
                    controlId={`${controlId}`}
                    name={`${controlId}`}
                    placeholder={selecteplaceholder}
                    onChange={(event) => handleSelectChange(id, event)}
                    hasExplanation={hasExplanation}
                    textContent={textContent}
                  />
                </Col>
                <Col>
                  <FormText
                    type="text"
                    name={`${textname}`}
                    label={textplaceholder}
                    placeholder={textplaceholder}
                    value={value}
                    isRequired={textRequired}
                    hideLabel={true}
                    onChange={(event) => handleTextChange(id, event)}
                    key={`${textname}`}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  const Subsection = ({ title }) => {
    return (
      <Col xs={12} className="subsection">
        <h3 className="h4">{title}</h3>
      </Col>
    );
  };

  const CrossBordersDetailsSection = () => {
    return (
      <>
        <Container fluid className="mb-3">
          <Row>
            <Col xs={12} xl={4}>
              <SelectForm
                options={bordersCodes}
                label={t("forms.labels.formA.claimant_domicile")}
                defaultVal={
                  crossBorderSections &&
                  crossBorderSections.domicileOrHabitualResidenceOfClaimant
                    ? crossBorderSections.domicileOrHabitualResidenceOfClaimant
                    : ""
                }
                controlId={`domicileOrHabitualResidenceOfClaimant_`}
                name={`domicileOrHabitualResidenceOfClaimant_`}
                placeholder={t("forms.labels.select.code")}
                onChange={(event) => {
                  handleSelectChange(
                    "crossBorderNatureOfCase",
                    "domicileOrHabitualResidenceOfClaimant",
                    id,
                    event,
                    setCrossBorderSections,
                    crossBorderSections
                  );
                }}
                hasExplanation={false}
                textContent=""
              />
            </Col>
            <Col xs={12} xl={4}>
              <SelectForm
                options={bordersCodes}
                label={t("forms.labels.formA.defendant_domicile")}
                defaultVal={
                  crossBorderSections &&
                  crossBorderSections.domicileOrHabitualResidenceOfDefendant
                    ? crossBorderSections.domicileOrHabitualResidenceOfDefendant
                    : ""
                }
                controlId={`domicileOrHabitualResidenceOfDefendant_`}
                name={`domicileOrHabitualResidenceOfDefendant_`}
                placeholder={t("forms.labels.select.code")}
                onChange={(event) => {
                  handleSelectChange(
                    "crossBorderNatureOfCase",
                    "domicileOrHabitualResidenceOfDefendant",
                    id,
                    event,
                    setCrossBorderSections,
                    crossBorderSections
                  );
                }}
                hasExplanation={false}
                textContent=""
              />
            </Col>
            <Col xs={12} xl={4}>
              <SelectForm
                options={bordersCodes}
                label={t("forms.labels.formA.court_county")}
                defaultVal={
                  crossBorderSections && crossBorderSections.countyOfCourt
                    ? crossBorderSections.countyOfCourt
                    : ""
                }
                controlId={`countyOfCourt_`}
                name={`countyOfCourt_`}
                placeholder={t("forms.labels.select.code")}
                onChange={(event) => {
                  handleSelectChange(
                    "crossBorderNatureOfCase",
                    "countyOfCourt",
                    id,
                    event,
                    setCrossBorderSections,
                    crossBorderSections
                  );
                }}
                hasExplanation={false}
                textContent=""
              />
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  const BankDetailsSection = () => {
    return (
      <>
        <Subsection title={`5.1 ${t("forms.labels.formA.court_county")}`} />
        <div className="section px-0 border-bottom border-3">
          <FormARepeaterLayout
            options={bankCodes}
            defaultVal={bankDetails && bankDetails.code ? bankDetails.code : ""}
            value={bankDetails && bankDetails.other ? bankDetails.other : ""}
            handleTextChange={(id, event) =>
              handleTextChange(
                "bankDetails",
                "other",
                id,
                event.target.value,
                setBankDetails,
                bankDetails
              )
            }
            handleSelectChange={(id, event) =>
              handleSelectChange(
                "bankDetails",
                "code",
                id,
                event,
                setBankDetails,
                bankDetails
              )
            }
            controlId="bank_"
            selecteplaceholder={t("forms.labels.select.code")}
            textRequired={false}
            textname="code_"
            textplaceholder={`${t(
              "forms.labels.placeholders.code_specification"
            )} 05`}
            hasExplanation={true}
            textContent={`*${t("forms.labels.formA.court_fees_details")}`}
          />
        </div>
        <div className="section">
          <Subsection
            title={`5.2 ${t("forms.labels.formA.defendant_payment")}`}
          />
          <Row>
            <Col md={6}>
              <FormText
                type="text"
                name="account_holder"
                label={t("forms.labels.placeholders.account_holder")}
                placeholder={t("forms.labels.placeholders.account_holder")}
                value={
                  bankDetails &&
                  bankDetails.account_information &&
                  bankDetails.account_information.accountHolderName
                    ? bankDetails.account_information.accountHolderName
                    : ""
                }
                isRequired={false}
                hideLabel={true}
                onChange={(event) =>
                  handleTextChange(
                    "bankDetails",
                    "account_information",
                    id,
                    event.target.value,
                    setBankDetails,
                    bankDetails,
                    "accountHolderName"
                  )
                }
                key="account_holder"
              />
            </Col>
            <Col md={6}>
              <FormText
                type="text"
                name="bic"
                label={t("forms.labels.placeholders.bic")}
                placeholder={t("forms.labels.placeholders.bic")}
                value={
                  bankDetails &&
                  bankDetails.account_information &&
                  bankDetails.account_information.bic
                    ? bankDetails.account_information.bic
                    : ""
                }
                isRequired={false}
                hideLabel={true}
                onChange={(event) =>
                  handleTextChange(
                    "bankDetails",
                    "account_information",
                    id,
                    event.target.value,
                    setBankDetails,
                    bankDetails,
                    "bic"
                  )
                }
                key="bic"
              />
            </Col>
            <Col md={6}>
              <FormText
                type="text"
                name="accountNumber"
                label={t("forms.labels.placeholders.account_number")}
                placeholder={t("forms.labels.placeholders.account_number")}
                value={
                  bankDetails &&
                  bankDetails.account_information &&
                  bankDetails.account_information.accountNumber
                    ? bankDetails.account_information.accountNumber
                    : ""
                }
                isRequired={false}
                hideLabel={true}
                onChange={(event) =>
                  handleTextChange(
                    "bankDetails",
                    "account_information",
                    id,
                    event.target.value,
                    setBankDetails,
                    bankDetails,
                    "accountNumber"
                  )
                }
                key="accountNumber"
              />
            </Col>
            <Col md={6}>
              <FormText
                type="text"
                name="iban"
                label={t("forms.labels.placeholders.iban")}
                placeholder={t("forms.labels.placeholders.iban")}
                pattern="^[A-Z]{2}[0-9]{2}[A-Z0-9]{11,30}$"
                value={
                  bankDetails &&
                  bankDetails.account_information &&
                  bankDetails.account_information.iban
                    ? bankDetails.account_information.iban
                    : ""
                }
                isRequired={false}
                hideLabel={true}
                onChange={(event) =>
                  handleTextChange(
                    "bankDetails",
                    "account_information",
                    id,
                    event.target.value,
                    setBankDetails,
                    bankDetails,
                    "iban"
                  )
                }
                key="iban"
              />
            </Col>
          </Row>
        </div>
      </>
    );
  };

  const PrincipalDetailsSection = () => {
    return (
      <>
        <div className="section px-0">
          <Container fluid className="bg-fadeblue">
            <Row className="align-items-start border-top border-3 border-secondary">
              <Col md={5} className=" py-3 border-end border-3 border-warning">
                <SelectForm
                  options={currencyCodes}
                  defaultVal={
                    principalDetails && principalDetails.currency
                      ? principalDetails.currency
                      : ""
                  }
                  controlId="currency_code"
                  name="currency_code"
                  placeholder={t("forms.labels.select.code")}
                  label="Currency"
                  onChange={(event) =>
                    handleSelectChange(
                      "principal",
                      "currency",
                      id,
                      event,
                      setPrincipalDetails,
                      principalDetails
                    )
                  }
                />
              </Col>
              <Col md={7} className=" py-3 formd-layout-dates ">
                <FormText
                  type="number"
                  pattern="^\d+(\.\d{1,2})?$"
                  name="principal_total_amount"
                  inputCustomClass="mt-1"
                  label={`${t("forms.labels.formA.principal_value_total")}`}
                  placeholder=""
                  value={
                    principalDetails && principalDetails.totalAmount
                      ? principalDetails.totalAmount
                      : ""
                  }
                  layout="vertical"
                  isRequired={false}
                  hideLabel={false}
                  onChange={(event) =>
                    handleTextChange(
                      "principal",
                      "totalAmount",
                      id,
                      event.target.value,
                      setPrincipalDetails,
                      principalDetails
                    )
                  }
                  key="principal_total_amount"
                />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section px-0">
          {principalSections.length > 0 && (
            <>
              {principalSections.map((section, index) => (
                <Container
                  fluid
                  key={section.id}
                  className={
                    index > 0 ? "border-top border-bottom border-3 pt-3" : ""
                  }
                >
                  {index !== 0 && (
                    <div className="remove-btn-wrapper">
                      <Button
                        variant="link"
                        className="p-0 mb-3 h-auto remove-section ms-auto"
                        onClick={() =>
                          removeSection(
                            section.id,
                            setPrincipalSections,
                            principalSections,
                            "claims"
                          )
                        }
                      >
                        <X size="25px" color="264687" />
                        <span className="visually-hidden">Remove</span>
                      </Button>
                    </div>
                  )}
                  <Row>
                    <Col xxl={4} className="col-md">
                      <Row>
                        <Col xxl={4}>
                          <FormText
                            type="text"
                            name={`id_${section.id}`}
                            label={`${t("forms.labels.formA.identifier")}`}
                            disabled={true}
                            placeholder=""
                            value={section.id}
                            layout="vertical"
                            isRequired={false}
                            hideLabel={false}
                            onChange={(event) =>
                              handleArrayTextChange(
                                "principal",
                                section.id,
                                setPrincipalSections,
                                principalSections,
                                event.target.value,
                                "claims",
                                "id"
                              )
                            }
                            key={`id_${section.id}`}
                          />
                        </Col>
                        <Col xxl={8}>
                          <SelectForm
                            options={code1Codes}
                            defaultVal={section.code1}
                            controlId={`principal_code1_${section.id}`}
                            name={`principal_code1_${section.id}`}
                            placeholder={t("forms.labels.select.claim_relates")}
                            label={`${t("forms.labels.formA.status_code")} 1`}
                            onChange={(event) =>
                              handleArrayTextChange(
                                "principal",
                                section.id,
                                setPrincipalSections,
                                principalSections,
                                event.value,
                                "claims",
                                "code1",
                                "id"
                              )
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col xxl={4} className="col-md">
                      <SelectForm
                        options={code2Codes}
                        defaultVal={section.code2}
                        controlId={`code2_${section.id}`}
                        name={`code2_${section.id}`}
                        placeholder={t(
                          "forms.labels.select.circumstances_invoked"
                        )}
                        label={`${t("forms.labels.formA.status_code")} 2`}
                        onChange={(event) =>
                          handleArrayTextChange(
                            "principal",
                            section.id,
                            setPrincipalSections,
                            principalSections,
                            event.value,
                            "claims",
                            "code2",
                            "id"
                          )
                        }
                      />
                    </Col>
                    <Col xxl={4} className="col-md">
                      <SelectForm
                        options={code3Codes}
                        defaultVal={section.code3}
                        controlId={`code3_${section.id}`}
                        name={`code3_${section.id}`}
                        placeholder={t("forms.labels.select.other_details")}
                        label={`${t("forms.labels.formA.status_code")} 3`}
                        onChange={(event) =>
                          handleArrayTextChange(
                            "principal",
                            section.id,
                            setPrincipalSections,
                            principalSections,
                            event.value,
                            "claims",
                            "code3",
                            "id"
                          )
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xxl={4} className="col-md">
                      <FormText
                        type="text"
                        name={`explanatory_statement_${section.id}`}
                        label={`${t("forms.labels.formA.explanation")}`}
                        pattern="^[\s\S]*$"
                        placeholder=""
                        value={section.explanatoryStatement}
                        layout="vertical"
                        isRequired={false}
                        hideLabel={false}
                        onChange={(event) =>
                          handleArrayTextChange(
                            "principal",
                            section.id,
                            setPrincipalSections,
                            principalSections,
                            event.target.value,
                            "claims",
                            "explanatoryStatement",
                            "id"
                          )
                        }
                        key={`explanatory_statement_${section.id}`}
                      />
                    </Col>
                    <Col xxl={4} className="col-md">
                      <CalendarComponent
                        label={`principal_claim_date_${section.id}`}
                        haslabel={true}
                        isRange={true}
                        isRequired={false}
                        labeltext={`${t("forms.labels.formA.date_or_period")}`}
                        handleChange={(date, value) => {
                          let val = {
                            id: section.id,
                            code2: section.code2,
                            code1: section.code1,
                            code3: section.code3,
                            explanatoryStatement: section.explanatoryStatement,
                            date: value.start,
                            endDate: value.end,
                            amount: section.amount,
                          };
                          handleArrayTextChange(
                            "principal",
                            section.id,
                            setPrincipalSections,
                            principalSections,
                            val,
                            "claims",
                            null, //empty
                            "id"
                          );
                        }}
                        selectedStartDate={section.date}
                        selectedEndDate={section.endDate}
                        error={false}
                      />
                    </Col>
                    <Col xxl={4} className="col-md">
                      <FormText
                        type="number"
                        pattern="^\d+(\.\d{1,2})?$"
                        name={`amount${section.id}`}
                        label={t("forms.labels.placeholders.ammount")}
                        placeholder=""
                        value={section.amount}
                        layout="vertical"
                        isRequired={false}
                        hideLabel={false}
                        onChange={(event) =>
                          handleArrayTextChange(
                            "principal",
                            section.id,
                            setPrincipalSections,
                            principalSections,
                            event.target.value,
                            "claims",
                            "amount",
                            "id"
                          )
                        }
                        key={`amount${section.id}`}
                      />
                    </Col>
                  </Row>
                </Container>
              ))}
            </>
          )}

          <div className="info-section py-3 px-0 bg-fadeblue border-top border-bottom border-3 border-secondary">
            <Container fluid>
              <Row>
                <Col xs={12}>
                  <h3 className="h4 form-label mb-0">
                    {`${t("forms.labels.formA.claimant_signature")} `}
                  </h3>
                </Col>
              </Row>
            </Container>
          </div>
          <Container fluid>
            <Row>
              <Col xs={12} className="form-section">
                <Row>
                  <Col md={6}>
                    <FormText
                      label={`${t("forms.labels.general.corporate_name")}`}
                      type="text"
                      name="principal_corporate_name"
                      value={
                        principalDetails &&
                        principalDetails.assignedClaim &&
                        principalDetails.assignedClaim.corporate_name
                          ? principalDetails.assignedClaim.corporate_name
                          : ""
                      }
                      onChange={(event) =>
                        handleTextChange(
                          "principal",
                          "assignedClaim",
                          id,
                          event.target.value,
                          setPrincipalDetails,
                          principalDetails,
                          "corporate_name"
                        )
                      }
                      disabled={false}
                      isRequired={false}
                      key="principal_corporate_name"
                    />
                  </Col>
                  <Col md={6}>
                    <FormText
                      label={`${t("forms.labels.general.id_code")}`}
                      type="text"
                      name="principal_full_name_party_idcode"
                      value={
                        principalDetails &&
                        principalDetails.assignedClaim &&
                        principalDetails.assignedClaim.identificationCodeString
                          ? principalDetails.assignedClaim
                              .identificationCodeString
                          : ""
                      }
                      onChange={(event) =>
                        handleTextChange(
                          "principal",
                          "assignedClaim",
                          id,
                          event.target.value,
                          setPrincipalDetails,
                          principalDetails,
                          "identificationCodeString"
                        )
                      }
                      disabled={false}
                      isRequired={false}
                      key="principal_full_name_party_idcode"
                    />
                  </Col>
                  <Col md={6}>
                    <FormText
                      label={`${t("forms.labels.general.surname")}`}
                      type="text"
                      name="principal_surname"
                      value={
                        principalDetails &&
                        principalDetails.assignedClaim &&
                        principalDetails.assignedClaim.surname
                          ? principalDetails.assignedClaim.surname
                          : ""
                      }
                      onChange={(event) =>
                        handleTextChange(
                          "principal",
                          "assignedClaim",
                          id,
                          event.target.value,
                          setPrincipalDetails,
                          principalDetails,
                          "surname"
                        )
                      }
                      disabled={false}
                      isRequired={false}
                      key="principal_surname"
                    />
                  </Col>
                  <Col md={6}>
                    <FormText
                      label={`${t("forms.labels.general.first_name")}`}
                      type="text"
                      name="principal_first_name"
                      value={
                        principalDetails &&
                        principalDetails.assignedClaim &&
                        principalDetails.assignedClaim.first_name
                          ? principalDetails.assignedClaim.first_name
                          : ""
                      }
                      onChange={(event) =>
                        handleTextChange(
                          "principal",
                          "assignedClaim",
                          id,
                          event.target.value,
                          setPrincipalDetails,
                          principalDetails,
                          "first_name"
                        )
                      }
                      disabled={false}
                      isRequired={false}
                      key="principal_first_name"
                    />
                  </Col>
                  <Col md={4}>
                    <FormText
                      label={`${t("forms.labels.general.address")}`}
                      type="text"
                      name="principal_party_address"
                      value={
                        principalDetails &&
                        principalDetails.assignedClaim &&
                        principalDetails.assignedClaim.address
                          ? principalDetails.assignedClaim.address
                          : ""
                      }
                      onChange={(event) =>
                        handleTextChange(
                          "principal",
                          "assignedClaim",
                          id,
                          event.target.value,
                          setPrincipalDetails,
                          principalDetails,
                          "address"
                        )
                      }
                      disabled={false}
                      isRequired={false}
                      key="principal_party_address"
                    />
                  </Col>
                  <Col md={2}>
                    <FormText
                      label={`${t("forms.labels.general.postal_code")}`}
                      type="text"
                      pattern="^[A-Za-z0-9\- ]{3,10}$"
                      name="principal_party_postalcode"
                      value={
                        principalDetails &&
                        principalDetails.assignedClaim &&
                        principalDetails.assignedClaim.postal_code
                          ? principalDetails.assignedClaim.postal_code
                          : ""
                      }
                      onChange={(event) =>
                        handleTextChange(
                          "principal",
                          "assignedClaim",
                          id,
                          event.target.value,
                          setPrincipalDetails,
                          principalDetails,
                          "postal_code"
                        )
                      }
                      disabled={false}
                      isRequired={false}
                      key="principal_party_postalcode"
                    />
                  </Col>
                  <Col md={3}>
                    <FormText
                      label={`${t("forms.labels.general.city")}`}
                      type="text"
                      name="principal_party_city"
                      value={
                        principalDetails &&
                        principalDetails.assignedClaim &&
                        principalDetails.assignedClaim.city
                          ? principalDetails.assignedClaim.city
                          : ""
                      }
                      onChange={(event) =>
                        handleTextChange(
                          "principal",
                          "assignedClaim",
                          id,
                          event.target.value,
                          setPrincipalDetails,
                          principalDetails,
                          "city"
                        )
                      }
                      disabled={false}
                      isRequired={false}
                      key="principal_party_city"
                    />
                  </Col>
                  <Col md={3}>
                    <FormText
                      label={`${t("forms.labels.general.country")}`}
                      type="text"
                      name="principal_party_country"
                      value={
                        principalDetails &&
                        principalDetails.assignedClaim &&
                        principalDetails.assignedClaim.country
                          ? principalDetails.assignedClaim.country
                          : ""
                      }
                      onChange={(event) =>
                        handleTextChange(
                          "principal",
                          "assignedClaim",
                          id,
                          event.target.value,
                          setPrincipalDetails,
                          principalDetails,
                          "country"
                        )
                      }
                      disabled={false}
                      isRequired={false}
                      key="principal_party_country"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>

          <div className="info-section py-3 px-0 bg-fadeblue border-top border-bottom border-3 border-secondary">
            <Container fluid>
              <Row>
                <Col xs={12}>
                  <h3 className="h4 form-label mb-0">
                    {`${t("forms.labels.formA.consumer_contract_details")}`}
                  </h3>
                </Col>
              </Row>
            </Container>
          </div>

          <Container fluid>
            <Row>
              <Col xs={12}>
                <Row className="py-3 border-bottom border-3 border-secondary">
                  <Col lg={7} className="align-self-center">
                    <span>
                      {`${t("forms.labels.formA.consumer_contract_matter")}`}
                    </span>
                  </Col>
                  <Col lg={5}>
                    <RadiosForm
                      label={`${t(
                        "forms.labels.formA.consumer_contract_matter"
                      )}`}
                      hideLabel={true}
                      name="concerns_consumer_contract"
                      options={[
                        { label: t("forms.labels.formA.yes"), value: "yes" },
                        { label: t("forms.labels.formA.no"), value: "no" },
                      ]}
                      selectedValue={
                        principalDetails?.customerContract
                          ?.concernsConsumerContract === true
                          ? "yes"
                          : principalDetails?.customerContract
                              ?.concernsConsumerContract === false
                          ? "no"
                          : null
                      }
                      onChange={(e) => {
                        var val =
                          e.target.value != null
                            ? e.target.value == "yes"
                              ? true
                              : false
                            : null;
                        handleTextChange(
                          "principal",
                          "customerContract",
                          id,
                          val,
                          setPrincipalDetails,
                          principalDetails,
                          "concernsConsumerContract"
                        );
                      }} // Convert string to boolean
                      isRequired={false}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={12}>
                <Row className="py-3 border-bottom border-3 border-secondary">
                  <Col lg={7} className="align-self-center">
                    <span>{`${t(
                      "forms.labels.formA.defendant_is_consumer"
                    )}`}</span>
                  </Col>
                  <Col lg={5}>
                    <RadiosForm
                      label={`${t("forms.labels.formA.defendant_is_consumer")}`}
                      hideLabel={true}
                      name="defendant_is_consumer"
                      options={[
                        { label: t("forms.labels.formA.yes"), value: "yes" },
                        { label: t("forms.labels.formA.no"), value: "no" },
                      ]}
                      selectedValue={
                        principalDetails?.customerContract
                          ?.defendantIsConsumer === true
                          ? "yes"
                          : principalDetails?.customerContract
                              ?.defendantIsConsumer === false
                          ? "no"
                          : null
                      }
                      // Your state for the selected value
                      onChange={(e) => {
                        var val =
                          e.target.value != null
                            ? e.target.value == "yes"
                              ? true
                              : false
                            : null;
                        handleTextChange(
                          "principal",
                          "customerContract",
                          id,
                          val,
                          setPrincipalDetails,
                          principalDetails,
                          "defendantIsConsumer"
                        );
                      }}
                      isRequired={false}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={12}>
                <Row className="py-3">
                  <Col lg={7} className="align-self-center">
                    <span>
                      {`${t("forms.labels.formA.article_59_domicile")}`}
                    </span>
                  </Col>
                  <Col lg={5}>
                    <RadiosForm
                      label={`${t("forms.labels.formA.article_59_domicile")}`}
                      hideLabel={true}
                      name="defendant_domiciled_with_court"
                      options={[
                        { label: t("forms.labels.formA.yes"), value: "yes" },
                        { label: t("forms.labels.formA.no"), value: "no" },
                      ]}
                      selectedValue={
                        principalDetails?.customerContract
                          ?.defendantDomiciledWithCourt === true
                          ? "yes"
                          : principalDetails?.customerContract
                              ?.defendantDomiciledWithCourt === false
                          ? "no"
                          : null
                      }
                      // Your state for the selected value
                      onChange={(e) => {
                        var val =
                          e.target.value != null
                            ? e.target.value == "yes"
                              ? true
                              : false
                            : null;
                        handleTextChange(
                          "principal",
                          "customerContract",
                          id,
                          val,
                          setPrincipalDetails,
                          principalDetails,
                          "defendantDomiciledWithCourt"
                        );
                      }}
                      isRequired={false}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  };

  const CustomHeader = ({ setSection, sections, type }) => {
    return (
      <div className="accordion-action-button">
        <Button
          variant="primary"
          className="h-auto ms-auto"
          onClick={() => addNewSection(setSection, sections, type)}
        >
          <span className="h2 plus-btn">{t("components.buttons.add")}</span>{" "}
          <Plus size="20px" />
        </Button>
      </div>
    );
  };

  const IntererDetailsSection = () => {
    return (
      <div className="section px-0">
        {interestSections.length > 0 && (
          <>
            {interestSections.map((section, index) => (
              <div
                key={index}
                className={index > 0 ? "border-top border-3 pt-3" : ""}
              >
                <Container fluid>
                  {index !== 0 && (
                    <div className="remove-btn-wrapper">
                      <Button
                        variant="link"
                        className="p-0 mb-3 h-auto remove-section ms-auto"
                        onClick={() =>
                          removeSection(
                            section.referencedClaimId,
                            setInterestSections,
                            interestSections,
                            "interest",
                            "referencedClaimId"
                          )
                        }
                      >
                        <X size="25px" color="264687" />
                        <span className="visually-hidden">Remove</span>
                      </Button>
                    </div>
                  )}
                  <Row>
                    <Col xxl={6}>
                      <Row>
                        <Col xxl={4}>
                          <FormText
                            type="text"
                            name={`referenced_claim_id_${section.referencedClaimId}`}
                            label={`${t("forms.labels.formA.identifier")}`}
                            disabled={true}
                            placeholder=""
                            value={section.referencedClaimId}
                            layout="vertical"
                            isRequired={false}
                            hideLabel={false}
                            key={`referenced_claim_id_${section.referencedClaimId}`}
                          />
                        </Col>
                        <Col xxl={8}>
                          <SelectForm
                            options={interestCodes}
                            defaultVal={
                              section &&
                              section.code &&
                              section.code.interestGround &&
                              section.code.interestGround.code
                                ? section.code.interestGround.code
                                : ""
                            }
                            controlId={`code_${section.referencedClaimId}`}
                            name={`code_${section.referencedClaimId}`}
                            placeholder={t("forms.labels.select.code")}
                            label={`${t("forms.labels.formA.status_code")}`}
                            onChange={(event) => {
                              let val = {
                                ...section.code,
                                interestGround: {
                                  ...section.code.interestGround,
                                  code: event.value,
                                },
                              };

                              handleArrayTextChange(
                                "interest",
                                section.referencedClaimId,
                                setInterestSections,
                                interestSections,
                                val,
                                "code",
                                "code",
                                "referencedClaimId"
                              );
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col xxl={6}>
                      <Row>
                        <Col xxl={6}>
                          <SelectForm
                            options={interestLetterCodes}
                            defaultVal={
                              section &&
                              section.code &&
                              section.code.interestPeriod &&
                              section.code.interestPeriod.code
                                ? section.code.interestPeriod.code
                                : ""
                            }
                            controlId={`letter_${section.referencedClaimId}`}
                            name={`letter_${section.referencedClaimId}`}
                            placeholder={t("forms.labels.select.code")}
                            label={`${t(
                              "forms.labels.formA.correspondence_letter"
                            )}`}
                            onChange={(event) => {
                              let val = {
                                ...section.code,
                                interestPeriod: {
                                  ...section.code.interestPeriod,
                                  code: event.value,
                                },
                              };
                              handleArrayTextChange(
                                "interest",
                                section.referencedClaimId,
                                setInterestSections,
                                interestSections,
                                val,
                                "code",
                                "code",
                                "referencedClaimId"
                              );
                            }}
                          />
                        </Col>
                        <Col xxl={6} className="col-md">
                          <FormText
                            type="number"
                            name={`interest_rate_${section.referencedClaimId}`}
                            label={`${t(
                              "forms.labels.formA.interest_rate_value"
                            )}(%)`}
                            placeholder=""
                            pattern="^\d+(\.\d{1,2})?$"
                            value={
                              section && section.interestRate != null
                                ? section.interestRate
                                : ""
                            }
                            layout="vertical"
                            isRequired={false}
                            hideLabel={false}
                            onChange={(event) =>
                              handleArrayTextChange(
                                "interest",
                                section.referencedClaimId,
                                setInterestSections,
                                interestSections,
                                event.target.value,
                                "interest",
                                "interestRate",
                                "referencedClaimId",
                                "array"
                              )
                            }
                            key={`interest_rate_${section.referencedClaimId}`}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col xxl={3} className="col-md">
                      <FormText
                        type="number"
                        name={`interest_rate_over_ecb_${section.referencedClaimId}`}
                        label={`% ${t(
                          "forms.labels.formA.base_rate_difference"
                        )}`}
                        placeholder=""
                        pattern="^\d+(\.\d{1,2})?$"
                        value={
                          section && section.interestRateOverEcb != null
                            ? section.interestRateOverEcb
                            : ""
                        }
                        layout="vertical"
                        isRequired={false}
                        hideLabel={false}
                        onChange={(event) =>
                          handleArrayTextChange(
                            "interest",
                            section.referencedClaimId,
                            setInterestSections,
                            interestSections,
                            event.target.value,
                            "interest",
                            "interestRateOverEcb",
                            "referencedClaimId",
                            "array"
                          )
                        }
                        key={`interest_rate_over_ecb_${section.referencedClaimId}`}
                      />
                    </Col>
                    <Col xxl={3} className="col-md">
                      <FormText
                        type="number"
                        pattern="^\d+(\.\d{1,2})?$"
                        name={`on_amount_${section.referencedClaimId}`}
                        label={`${t("forms.labels.formA.interest_on_amount")}`}
                        placeholder=""
                        value={
                          section && section.onAmount != null
                            ? section.onAmount
                            : ""
                        }
                        layout="vertical"
                        isRequired={false}
                        hideLabel={false}
                        onChange={(event) =>
                          handleArrayTextChange(
                            "interest",
                            section.referencedClaimId,
                            setInterestSections,
                            interestSections,
                            event.target.value,
                            "interest",
                            "onAmount",
                            "referencedClaimId",
                            "array"
                          )
                        }
                        key={`on_amount_${section.referencedClaimId}`}
                      />
                    </Col>

                    <Col xxl={3} className="col-md">
                      <CalendarComponent
                        label={`starting_from_${section.referencedClaimId}`}
                        haslabel={true}
                        labeltext={`${t(
                          "forms.labels.formA.interest_start_date"
                        )}`}
                        handleChange={(date, value) => {
                          handleArrayTextChange(
                            "interest",
                            section.referencedClaimId,
                            setInterestSections,
                            interestSections,
                            value,
                            "interest",
                            "startingFrom",
                            "referencedClaimId",
                            "array"
                          );
                        }}
                        isRequired={false}
                        date={section.startingFrom}
                        error={false}
                      />
                    </Col>
                    <Col xxl={3} className="col-md">
                      <CalendarComponent
                        label={`to_${section.referencedClaimId}`}
                        haslabel={true}
                        labeltext={`${t(
                          "forms.labels.formA.interest_end_date"
                        )}`}
                        handleChange={(date, value) => {
                          handleArrayTextChange(
                            "interest",
                            section.referencedClaimId,
                            setInterestSections,
                            interestSections,
                            value,
                            "interest",
                            "to",
                            "referencedClaimId",
                            "array"
                          );
                        }}
                        date={section.to}
                        error={false}
                        isRequired={false}
                      />
                    </Col>
                  </Row>
                </Container>
                <div className="info-section py-3 px-0 bg-fadeblue border-top border-bottom border-3 border-secondary">
                  <Container fluid>
                    <Row>
                      <Col xs={12}>
                        <h3 className="h4 form-label mb-0">
                          {`${t("forms.labels.formA.special_case_code")}`}
                        </h3>
                      </Col>
                    </Row>
                  </Container>
                </div>
                <Container fluid className="pt-3">
                  <Row>
                    <Col lg={6}>
                      <FormTextArea
                        label={`${t("forms.labels.formA.status_code")} 6`}
                        name={`interestGround_other_${section.referencedClaimId}`}
                        value={
                          section &&
                          section.code &&
                          section.code.interestGround &&
                          section.code.interestGround.other
                            ? section.code.interestGround.other
                            : ""
                        }
                        rowNum={10}
                        hideLabel={false}
                        required={false}
                        onChange={(event) => {
                          let val = {
                            ...section.code,
                            interestGround: {
                              ...section.code.interestGround,
                              other: event.target.value,
                            },
                          };

                          handleArrayTextChange(
                            "interest",
                            section.referencedClaimId,
                            setInterestSections,
                            interestSections,
                            val,
                            "code",
                            "code",
                            "referencedClaimId"
                          );
                        }}
                      />
                    </Col>
                    <Col lg={6}>
                      <FormTextArea
                        label={`${t("forms.labels.formA.status_code")} E`}
                        name={`interestPeriod_other_${section.referencedClaimId}`}
                        value={
                          section &&
                          section.code &&
                          section.code.interestPeriod &&
                          section.code.interestPeriod.other
                            ? section.code.interestPeriod.other
                            : ""
                        }
                        rowNum={10}
                        hideLabel={false}
                        required={false}
                        onChange={(event) => {
                          let val = {
                            ...section.code,
                            interestPeriod: {
                              ...section.code.interestPeriod,
                              other: event.target.value,
                            },
                          };

                          handleArrayTextChange(
                            "interest",
                            section.referencedClaimId,
                            setInterestSections,
                            interestSections,
                            val,
                            "code",
                            "code",
                            "referencedClaimId"
                          );
                        }}
                      />
                    </Col>
                  </Row>
                </Container>
              </div>
            ))}
          </>
        )}
      </div>
    );
  };

  const CostsDetailsSection = () => {
    return (
      <div className="section px-0">
        {costsSections.length > 0 && (
          <>
            {costsSections.map((section, index) => (
              <div
                key={index}
                className={index > 0 ? "border-top border-3 pt-3" : ""}
              >
                <Container fluid>
                  {index !== 0 && (
                    <div className="remove-btn-wrapper">
                      <Button
                        variant="link"
                        className="p-0 mb-3 h-auto remove-section ms-auto"
                        onClick={() =>
                          removeSection(
                            section.id,
                            setCostsSections,
                            costsSections,
                            "costs"
                          )
                        }
                      >
                        <X size="25px" color="264687" />
                        <span className="visually-hidden">Remove</span>
                      </Button>
                    </div>
                  )}
                  <Row>
                    <Col xxl={6}>
                      <SelectForm
                        options={costsCodes}
                        defaultVal={section && section.code ? section.code : ""}
                        controlId={`code_${section.id}`}
                        name={`code_${section.id}`}
                        placeholder={t("forms.labels.select.code")}
                        label=""
                        onChange={(event) => {
                          handleArrayTextChange(
                            "costs",
                            section.id,
                            setCostsSections,
                            costsSections,
                            event.value,
                            "costs",
                            "code",
                            "id",
                            "array"
                          );
                        }}
                      />
                    </Col>
                    <Col xxl={6}>
                      <FormText
                        type="text"
                        pattern="^[\s\S]*$"
                        name={`specification_${section.id}`}
                        label={`${t(
                          "forms.labels.placeholders.code_specification"
                        )} 2`}
                        placeholder={`${t(
                          "forms.labels.placeholders.code_specification"
                        )} 2`}
                        value={
                          section && section.specification
                            ? section.specification
                            : ""
                        }
                        layout="vertical"
                        isRequired={false}
                        hideLabel={true}
                        onChange={(event) =>
                          handleArrayTextChange(
                            "costs",
                            section.id,
                            setCostsSections,
                            costsSections,
                            event.target.value,
                            "costs",
                            "specification",
                            "id",
                            "array"
                          )
                        }
                        key={`specification_${section.id}`}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xxl={6}>
                      <SelectForm
                        options={currencyCodes}
                        defaultVal={
                          section && section.currency ? section.currency : ""
                        }
                        controlId={`currency_${section.id}`}
                        name={`currency_${section.id}`}
                        placeholder={t("forms.labels.select.currency")}
                        label=""
                        onChange={(event) => {
                          handleArrayTextChange(
                            "costs",
                            section.id,
                            setCostsSections,
                            costsSections,
                            event.value,
                            "costs",
                            "currency",
                            "id",
                            "array"
                          );
                        }}
                      />
                    </Col>
                    <Col xxl={6}>
                      <FormText
                        type="text"
                        name={`amount_${section.id}`}
                        pattern="^\d+(\.\d{1,2})?$"
                        label={t("forms.labels.placeholders.ammount")}
                        placeholder={t("forms.labels.placeholders.ammount")}
                        value={section && section.amount ? section.amount : ""}
                        layout="vertical"
                        isRequired={false}
                        hideLabel={true}
                        onChange={(event) =>
                          handleArrayTextChange(
                            "costs",
                            section.id,
                            setCostsSections,
                            costsSections,
                            event.target.value,
                            "costs",
                            "amount",
                            "id",
                            "array"
                          )
                        }
                        key={`amount_${section.id}`}
                      />
                    </Col>
                  </Row>
                </Container>
              </div>
            ))}
          </>
        )}
      </div>
    );
  };

  const EvidencesDetailsSection = () => {
    return (
      <div className="section px-0">
        {evidencesSections.length > 0 && (
          <>
            {evidencesSections.map((section, index) => (
              <div
                key={index}
                className={index > 0 ? "border-top border-3 pt-3" : ""}
              >
                <Container fluid>
                  {index !== 0 && (
                    <div className="remove-btn-wrapper">
                      <Button
                        variant="link"
                        className="p-0 mb-3 h-auto remove-section ms-auto"
                        onClick={() =>
                          removeSection(
                            section.referencedClaimId,
                            setEvidencesSections,
                            evidencesSections,
                            "evidences",
                            "referencedClaimId"
                          )
                        }
                      >
                        <X size="25px" color="264687" />
                        <span className="visually-hidden">Remove</span>
                      </Button>
                    </div>
                  )}
                  <Row className="align-items-end">
                    <Col xxl={4}>
                      <Row className="align-items-end">
                        <Col xxl={4}>
                          <FormText
                            type="text"
                            name={`evidence_referenced_claim_id_${section.referencedClaimId}`}
                            label="ID"
                            disabled={true}
                            placeholder=""
                            value={section.referencedClaimId}
                            layout="vertical"
                            isRequired={false}
                            hideLabel={false}
                            key={`evidence_referenced_claim_id_${section.referencedClaimId}`}
                          />
                        </Col>
                        <Col xxl={8}>
                          <SelectForm
                            options={evidenceCodes}
                            defaultVal={
                              section && section.code ? section.code : ""
                            }
                            controlId={`evidence_code_${section.referencedClaimId}`}
                            name={`evidence_code_${section.referencedClaimId}`}
                            placeholder={t("forms.labels.select.code")}
                            label=""
                            onChange={(event) => {
                              handleArrayTextChange(
                                "evidences",
                                section.referencedClaimId,
                                setEvidencesSections,
                                evidencesSections,
                                event.value,
                                "evidences",
                                "code",
                                "referencedClaimId",
                                "array"
                              );
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col xxl={4}>
                      <FormText
                        type="text"
                        pattern="^[\s\S]*$"
                        name={`evidence_description_${section.referencedClaimId}`}
                        label={t(
                          "forms.labels.placeholders.description_evidence"
                        )}
                        placeholder={t(
                          "forms.labels.placeholders.description_evidence"
                        )}
                        value={
                          section && section.description != null
                            ? section.description
                            : ""
                        }
                        layout="vertical"
                        isRequired={false}
                        hideLabel={true}
                        onChange={(event) =>
                          handleArrayTextChange(
                            "evidences",
                            section.referencedClaimId,
                            setEvidencesSections,
                            evidencesSections,
                            event.target.value,
                            "evidences",
                            "description",
                            "referencedClaimId",
                            "array"
                          )
                        }
                        key={`evidence_description_${section.referencedClaimId}`}
                      />
                    </Col>
                    <Col xxl={4} className="col-md">
                      <CalendarComponent
                        label={`evidence_date_${section.referencedClaimId}`}
                        haslabel={false}
                        isRequired={false}
                        labeltext={`${t("forms.labels.formA.date_or_period")}`}
                        handleChange={(date, value) => {
                          handleArrayTextChange(
                            "evidences",
                            section.referencedClaimId,
                            setEvidencesSections,
                            evidencesSections,
                            value,
                            "evidences",
                            "date",
                            "referencedClaimId",
                            "array"
                          );
                        }}
                        date={section.date}
                        error={false}
                        customClass="mb-3"
                      />
                    </Col>
                  </Row>
                </Container>
              </div>
            ))}
          </>
        )}
      </div>
    );
  };

  const AdditionalDetailsSection = () => {
    const handleEditorChange = (editor) => {
      let value = editor.display.input.prevInput;
      setEditorValue(value);
      handleTextChange(
        "additionalStatements",
        "text",
        id,
        value, // Pass the updated editor content here
        setAdditionalStatementsSections,
        additionalStatementsSections
      );
    };

    const editorRef = useRef(null);

    useEffect(() => {
      const editorElement = editorRef.current;

      if (editorElement) {
        const waitForCodeMirror = setInterval(() => {
          const codeMirrorContainer =
            editorElement.querySelector(".CodeMirror");

          if (codeMirrorContainer) {
            clearInterval(waitForCodeMirror); // Stop checking once found

            // Find the textarea element inside the CodeMirror container
            const textarea = codeMirrorContainer.querySelector("textarea");

            if (textarea) {
              // Set the custom ID for the textarea
              const customTextareaId = "customTextareaId";
              textarea.id = customTextareaId; // Assign custom ID to textarea

              // Create a visually hidden label and link it to the textarea
              const label = document.createElement("label");
              label.setAttribute("for", customTextareaId); // Link label to the textarea by ID
              label.textContent = "Enter your content:"; // You can set the label's text as needed

              // Add the label above the textarea
              // Instead of insertBefore, we'll append it to the parent of the textarea (codeMirrorContainer)
              codeMirrorContainer.prepend(label); // This will insert the label as the first child of the container

              // Make the label visually hidden but accessible to screen readers
              label.classList.add("visually-hidden");
            }
          }
        }, 100); // Check every 100ms

        // Optionally, clear the interval after some time to avoid an infinite loop
        setTimeout(() => clearInterval(waitForCodeMirror), 5000);
      }
    }, []);

    return (
      <div className="section px-0">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Form.Label
                htmlFor="additionalStatementscontent"
                className="visually-hidden"
              >
                Content
              </Form.Label>
              <SimpleMDE
                ref={editorRef}
                id="additionalStatementscontent"
                value={editorValue}
                events={{
                  blur: handleEditorChange,
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  const SignatureContent = () => {
    return (
      <Container fluid>
        <Row>
          <Col md={7} className="signature-court bg-fadeblue">
            <Row>
              <Col
                xxl={6}
                className="d-flex align-items-center bg-fadeblue  py-3 "
              >
                <FormText
                  type="text"
                  name="additional_signing_location"
                  customClass="fw-normal w-auto"
                  inputCustomClass="w-100"
                  isRequired={false}
                  pattern="^[\w\s\-]+$"
                  hideLabel={false}
                  value={
                    additionalStatementsSections &&
                    additionalStatementsSections.signature.signingLocation
                      ? additionalStatementsSections.signature.signingLocation
                      : ""
                  }
                  label={`${t("forms.labels.general.done_at")}:`}
                  onChange={(event) => {
                    handleTextChange(
                      "additionalStatements",
                      "signature",
                      id,
                      event.target.value,
                      setAdditionalStatementsSections,
                      additionalStatementsSections,
                      "signingLocation"
                    );
                  }}
                />
              </Col>
              <Col
                xxl={6}
                className="d-flex align-items-center bg-fadeblue border-start border-secondary border-3  py-3 "
              >
                <IconComponent
                  icon={<Calendar label="Calendar" primaryColor="#264687" />}
                  text={`${t("app.general.date")}:`}
                />
                <CalendarComponent
                  label="additional_signature_date"
                  handleChange={(date, value) => {
                    handleTextChange(
                      "additionalStatements",
                      "signature",
                      id,
                      value,
                      setAdditionalStatementsSections,
                      additionalStatementsSections,
                      "signingDate"
                    );
                  }}
                  date={
                    additionalStatementsSections &&
                    additionalStatementsSections.signature.signingDate
                      ? additionalStatementsSections.signature.signingDate
                      : ""
                  }
                  error={false}
                  isRequired={false}
                />
              </Col>
            </Row>
          </Col>
          <Col
            md={5}
            className="d-flex align-items-center  border-start border-3 py-3 border-warning"
          >
            <span className="fw-light">{`${t(
              "forms.labels.general.signature_stamp"
            )}`}</span>
          </Col>
        </Row>
      </Container>
    );
  };

  const Appendix1DetailsSection = () => {
    const [isCreditCode] = useState(
      appendix1Sections &&
        appendix1Sections.code &&
        appendix1Sections.code == "02"
    );

    const [acountHolder] = useState(
      isCreditCode
        ? appendix1Sections.creditCardInformation.creditCardOwner
        : appendix1Sections.sepaAccountInformation.accountHolderName
    );

    const [bicOrCreditCard] = useState(
      isCreditCode
        ? appendix1Sections.creditCardInformation.creditCardCompany
        : appendix1Sections.sepaAccountInformation.bic
    );

    const [accountNumberOrCreditCardNumber] = useState(
      isCreditCode
        ? appendix1Sections.creditCardInformation.creditCardNumber
        : appendix1Sections.sepaAccountInformation.accountNumber
    );

    const [IbanOrExpirasionDate] = useState(
      isCreditCode
        ? appendix1Sections.creditCardInformation.creditCardExpiryDate
        : appendix1Sections.sepaAccountInformation.iban
    );

    return (
      <Container fluid className="pb-3">
        <Row>
          <Subsection title={`${t("forms.labels.formA.bank_details")}`} />
          <Col xxl={6}>
            <SelectForm
              options={appendix1Codes}
              defaultVal={
                appendix1Sections && appendix1Sections.code
                  ? appendix1Sections.code
                  : ""
              }
              controlId={`appendix1_code`}
              name={`appendix1_code`}
              placeholder={t("forms.labels.select.code")}
              label=""
              onChange={(event) => {
                appendix1Sections.sepaAccountInformation = {};
                appendix1Sections.creditCardInformation = {};
                handleSelectChange(
                  "courtFees",
                  "code",
                  id,
                  event,
                  setAppendix1Sections,
                  appendix1Sections
                );
              }}
            />
          </Col>
          <Col xxl={6}>
            <FormText
              type="text"
              name="appendix1_account_holder"
              label={t("forms.labels.placeholders.account_holder")}
              placeholder={t("forms.labels.placeholders.account_holder")}
              value={appendix1Sections && acountHolder ? acountHolder : ""}
              isRequired={false}
              hideLabel={true}
              onChange={(event) => {
                let objectName = isCreditCode
                  ? "creditCardInformation"
                  : "sepaAccountInformation";
                let field = isCreditCode
                  ? "creditCardOwner"
                  : "accountHolderName";
                handleTextChange(
                  "courtFees",
                  objectName,
                  id,
                  event.target.value,
                  setAppendix1Sections,
                  appendix1Sections,
                  field
                );
              }}
              key="appendix1_account_holder"
            />
          </Col>
          <Col xxl={6}>
            <FormText
              type="text"
              name="appendix1_bic"
              label="Bank name (BIC) or other relevant bank code / Credit card company"
              placeholder="Bank name (BIC) or other relevant bank code / Credit card company"
              value={
                appendix1Sections && bicOrCreditCard ? bicOrCreditCard : ""
              }
              isRequired={false}
              hideLabel={true}
              onChange={(event) => {
                let objectName = isCreditCode
                  ? "creditCardInformation"
                  : "sepaAccountInformation";
                let field = isCreditCode ? "creditCardCompany" : "bic";
                handleTextChange(
                  "courtFees",
                  objectName,
                  id,
                  event.target.value,
                  setAppendix1Sections,
                  appendix1Sections,
                  field
                );
              }}
              key="appendix1_bic"
            />
          </Col>
          <Col xxl={6}>
            <FormText
              type="text"
              name="appendix1_account_number"
              label={t("forms.labels.placeholders.account_or_credit")}
              placeholder={t("forms.labels.placeholders.account_or_credit")}
              value={
                appendix1Sections && accountNumberOrCreditCardNumber
                  ? accountNumberOrCreditCardNumber
                  : ""
              }
              isRequired={false}
              hideLabel={true}
              onChange={(event) => {
                let objectName = isCreditCode
                  ? "creditCardInformation"
                  : "sepaAccountInformation";
                let field = isCreditCode ? "creditCardNumber" : "accountNumber";
                handleTextChange(
                  "courtFees",
                  objectName,
                  id,
                  event.target.value,
                  setAppendix1Sections,
                  appendix1Sections,
                  field
                );
              }}
              key="appendix1_account_number"
            />
          </Col>
          <Col xs={12}>
            <FormText
              type="text"
              name="appendix1_iban"
              label={t("forms.labels.placeholders.iban_or_card")}
              placeholder={t("forms.labels.placeholders.iban_or_card")}
              value={
                appendix1Sections && IbanOrExpirasionDate
                  ? IbanOrExpirasionDate
                  : ""
              }
              pattern={
                isCreditCode
                  ? "^(0[1-9]|1[0-2])/\\d{2}$"
                  : "^[A-Z]{2}[0-9]{2}[A-Z0-9]{11,30}$"
              }
              isRequired={false}
              hideLabel={true}
              onChange={(event) => {
                let objectName = isCreditCode
                  ? "creditCardInformation"
                  : "sepaAccountInformation";
                let field = isCreditCode ? "creditCardExpiryDate" : "iban";
                handleTextChange(
                  "courtFees",
                  objectName,
                  id,
                  event.target.value,
                  setAppendix1Sections,
                  appendix1Sections,
                  field
                );
              }}
              key="appendix1_iban"
            />
          </Col>
        </Row>
      </Container>
    );
  };

  const Appendix2DetailsSection = () => {
    return (
      <>
        <Container fluid className="pb-3">
          <Row>
            <Col xs={12} md={8}>
              <SelectForm
                options={appendix2Codes}
                defaultVal={
                  appendix2Sections && appendix2Sections.code
                    ? appendix2Sections.code
                    : ""
                }
                controlId={`appendex2_code`}
                name={`appendex2_code`}
                placeholder={t("forms.labels.select.code")}
                label={`${t("forms.labels.formA.opposition_procedure")}`}
                onChange={(event) => {
                  handleSelectChange(
                    "appendix2",
                    "code",
                    id,
                    event,
                    setAppendix2Sections,
                    appendix2Sections
                  );
                }}
              />
            </Col>
          </Row>
        </Container>
        <div className="info-section section py-3 px-0 bg-fadeblue border-top border-bottom border-3 border-secondary">
          <Container fluid>
            <Row className="align-items-center">
              <Col xs={12} lg={9}>
                <h3 className="h4 form-label mb-lg-0">
                  {`${t("forms.labels.formA.case_number")} :`}
                </h3>
              </Col>
              <Col xs={12} lg={3}>
                <FormText
                  type="text"
                  name="appendix2_case_number"
                  label={t("app.general.casenumber")}
                  placeholder={t("app.general.casenumber")}
                  pattern="^[\s\S]{1,256}$"
                  value={
                    appendix2Sections && appendix2Sections.caseNumber
                      ? appendix2Sections.caseNumber
                      : ""
                  }
                  isRequired={false}
                  hideLabel={true}
                  onChange={(event) =>
                    handleTextChange(
                      "appendix2",
                      "caseNumber",
                      id,
                      event.target.value,
                      setAppendix2Sections,
                      appendix2Sections
                    )
                  }
                  key="appendix2_case_number"
                />
              </Col>
            </Row>
          </Container>
        </div>
        <Container fluid>
          <Row>
            <Col xs={12} lg={4}>
              <FormText
                type="text"
                name="appendix2_company_name"
                label="Name of company or organization"
                placeholder=""
                pattern="^[\s\S]{1,256}$"
                value={
                  appendix2Sections && appendix2Sections.judicialName
                    ? appendix2Sections.judicialName
                    : ""
                }
                isRequired={false}
                hideLabel={false}
                onChange={(event) =>
                  handleTextChange(
                    "appendix2",
                    "judicialName",
                    id,
                    event.target.value,
                    setAppendix2Sections,
                    appendix2Sections
                  )
                }
                key="appendix2_company_name"
              />
            </Col>
            <Col xs={12} lg={4}>
              <FormText
                type="text"
                name="appendix2_first_name"
                label={`${t("forms.labels.general.first_name")}`}
                pattern="^[\s\S]{1,256}$"
                placeholder=""
                value={
                  appendix2Sections && appendix2Sections.firstName
                    ? appendix2Sections.firstName
                    : ""
                }
                isRequired={false}
                hideLabel={false}
                onChange={(event) =>
                  handleTextChange(
                    "appendix2",
                    "firstName",
                    id,
                    event.target.value,
                    setAppendix2Sections,
                    appendix2Sections
                  )
                }
                key="appendix2_first_name"
              />
            </Col>
            <Col xs={12} lg={4}>
              <FormText
                type="text"
                name="appendix2_surname"
                label={`${t("forms.labels.general.surname")}`}
                pattern="^[\s\S]{1,256}$"
                placeholder=""
                value={
                  appendix2Sections && appendix2Sections.surname
                    ? appendix2Sections.surname
                    : ""
                }
                isRequired={false}
                hideLabel={false}
                onChange={(event) =>
                  handleTextChange(
                    "appendix2",
                    "surname",
                    id,
                    event.target.value,
                    setAppendix2Sections,
                    appendix2Sections
                  )
                }
                key="appendix2_surname"
              />
            </Col>
          </Row>
        </Container>
        <div className="section px-0 border-top border-3 border-secondary pt-3">
          <Container fluid>
            <Row>
              <Subsection
                title={`${t("forms.labels.formA.claims_out_of_scope")} :`}
              />
              <Col xs={"auto"}>
                <CheckboxForm
                  handleChange={(e) => {
                    handleTextChange(
                      "appendix2",
                      "choice02AboveDiscontinue",
                      id,
                      e.target.checked,
                      setAppendix2Sections,
                      appendix2Sections
                    );
                  }}
                  reverse={true}
                  controlId="choice02AboveDiscontinue"
                  name="choice02AboveDiscontinue"
                  label={`${t("forms.labels.formA.proceedings_discontinue")}`}
                  customClass="form-label-size"
                  defaultChecked={
                    appendix2Sections &&
                    appendix2Sections.choice02AboveDiscontinue
                      ? appendix2Sections.choice02AboveDiscontinue
                      : ""
                  }
                />
              </Col>
              <Col xs={"auto"}>
                <CheckboxForm
                  handleChange={(e) => {
                    handleTextChange(
                      "appendix2",
                      "choice02AboveContinueNational",
                      id,
                      e.target.checked,
                      setAppendix2Sections,
                      appendix2Sections
                    );
                  }}
                  reverse={true}
                  controlId="choice02AboveContinueNational"
                  name="choice02AboveContinueNational"
                  label={`${t("forms.labels.formA.proceedings_continue")}`}
                  customClass="form-label-size"
                  defaultChecked={
                    appendix2Sections &&
                    appendix2Sections.choice02AboveContinueNational
                      ? appendix2Sections.choice02AboveContinueNational
                      : ""
                  }
                />
              </Col>
            </Row>
          </Container>
        </div>
        <Container fluid className="border-top border-3 border-secondary">
          <Row>
            <Col md={7} className="signature-court bg-fadeblue">
              <Row>
                <Col
                  xxl={6}
                  className="d-flex align-items-center bg-fadeblue  py-3 "
                >
                  <FormText
                    type="text"
                    name="appendix2_signing_location"
                    customClass="fw-normal w-auto"
                    inputCustomClass="w-100"
                    pattern="^[\s\S]{1,256}$"
                    isRequired={false}
                    hideLabel={false}
                    value={
                      appendix2Sections && appendix2Sections.doneAt
                        ? appendix2Sections.doneAt
                        : ""
                    }
                    label={`${t("forms.labels.general.done_at")}:`}
                    onChange={(event) => {
                      handleTextChange(
                        "appendix2",
                        "doneAt",
                        id,
                        event.target.value,
                        setAppendix2Sections,
                        appendix2Sections
                      );
                    }}
                  />
                </Col>
                <Col
                  xxl={6}
                  className="d-flex align-items-center bg-fadeblue border-start border-secondary border-3 py-3 "
                >
                  <IconComponent
                    icon={<Calendar label="Calendar" primaryColor="#264687" />}
                    text={`${t("app.general.date")}:`}
                  />
                  <CalendarComponent
                    label="appendix2_signature_date"
                    handleChange={(date, value) => {
                      handleTextChange(
                        "appendix2",
                        "signDate",
                        id,
                        value,
                        setAppendix2Sections,
                        appendix2Sections
                      );
                    }}
                    date={
                      appendix2Sections && appendix2Sections.signDate
                        ? appendix2Sections.signDate
                        : ""
                    }
                    error={false}
                    isRequired={false}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              md={5}
              className="d-flex align-items-center  border-start border-3 py-3 border-warning"
            >
              <span className="fw-light">{`${t(
                "forms.labels.general.signature_stamp"
              )}`}</span>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  return (
    <>
      {groundCodes != null && groundSections !== null && (
        <>
          <AccordionCard
            eventKey={1}
            isOpen={true}
            title={`3. ${t("forms.labels.sections.grounds")}`}
            body={
              <FormARepeaterLayout
                options={groundCodes}
                defaultVal={
                  groundSections && groundSections.code
                    ? groundSections.code
                    : ""
                }
                value={
                  groundSections && groundSections.specification
                    ? groundSections.specification
                    : ""
                }
                handleTextChange={(id, event) =>
                  handleTextChange(
                    "courtsJurisdiction",
                    "specification",
                    id,
                    event.target.value,
                    setGroundSections,
                    groundSections
                  )
                }
                handleSelectChange={(id, event) =>
                  handleSelectChange(
                    "courtsJurisdiction",
                    "code",
                    id,
                    event,
                    setGroundSections,
                    groundSections
                  )
                }
                controlId="courts_"
                selecteplaceholder={t("forms.labels.select.code")}
                textRequired={false}
                textname="jurisdiction_"
                textplaceholder={`${t(
                  "forms.labels.placeholders.code_specification"
                )} 14`}
              />
            }
            isDisabled={false}
            custom="section"
          />
        </>
      )}
      {bordersCodes != null && (
        <AccordionCard
          eventKey={2}
          isOpen={true}
          title={`4. ${t("forms.labels.sections.cross")}`}
          body={<CrossBordersDetailsSection />}
          isDisabled={false}
          custom="section"
        />
      )}

      {bankCodes != null && bankDetails != null && (
        <AccordionCard
          eventKey={3}
          isOpen={true}
          title={`5. ${t("forms.labels.sections.bank")}`}
          body={<BankDetailsSection />}
          isDisabled={false}
          custom="section"
        />
      )}
      {currencyCodes != null &&
        principalDetails != null &&
        code1Codes != null &&
        code2Codes != null &&
        code3Codes != null && (
          <AccordionCard
            eventKey={4}
            isOpen={true}
            title={`6. ${t("forms.labels.sections.principal")}`}
            body={<PrincipalDetailsSection />}
            isDisabled={false}
            custom="section"
            customHeader={
              <CustomHeader
                setSection={setPrincipalSections}
                sections={principalSections}
                type={{
                  id:
                    "ID" +
                    (principalSections.length < 9 ? "0" : "") +
                    (principalSections.length + 1),
                  code1: "",
                  code2: "",
                  code3: "",
                  explanatoryStatement: "",
                  date: null,
                  endDate: null,
                  amount: "",
                }}
              />
            }
          />
        )}
      {interestCodes != null &&
        interestSections != null &&
        interestLetterCodes !== null && (
          <AccordionCard
            eventKey={5}
            isOpen={true}
            title={`7. ${t("forms.labels.sections.interest")}`}
            body={<IntererDetailsSection />}
            isDisabled={false}
            custom="section"
            customHeader={
              <CustomHeader
                setSection={setInterestSections}
                sections={interestSections}
                type={{
                  referencedClaimId:
                    "ID" +
                    (interestSections.length < 9 ? "0" : "") +
                    (interestSections.length + 1),
                  code: {
                    interestGround: {
                      code: "",
                      other: "",
                    },
                    interest_period: {
                      code: "",
                      other: "",
                    },
                  },
                  interestRate: null,
                  interestRateOverEcb: null,
                  onAmount: null,
                  startingFrom: null,
                  to: null,
                }}
              />
            }
          />
        )}
      {penaltiesSections !== null && (
        <>
          <AccordionCard
            eventKey={6}
            isOpen={true}
            title={`8. ${t("forms.labels.sections.contractual_penalties")}`}
            body={
              <Container fluid className="mb-3">
                <Row>
                  <Col xs={12}>
                    <Row className="row-hover align-items-start">
                      <Col md={6}>
                        <FormText
                          type="number"
                          name={`penalties_amount`}
                          pattern="^\d+(\.\d{1,2})?$"
                          label={t("forms.labels.placeholders.ammount")}
                          placeholder={t("forms.labels.placeholders.ammount")}
                          value={
                            penaltiesSections && penaltiesSections.amount
                              ? penaltiesSections.amount
                              : null
                          }
                          isRequired={false}
                          hideLabel={true}
                          onChange={(event) => {
                            handleTextChange(
                              "contractualPenalties",
                              "amount",
                              id,
                              event.target.value,
                              setPenaltiesSections,
                              penaltiesSections
                            );
                          }}
                          key={`penalties_amount`}
                        />
                      </Col>
                      <Col>
                        <FormText
                          type="text"
                          name={`penalties_description`}
                          pattern="^[\s\S]*$"
                          label={t("forms.labels.placeholders.specify")}
                          placeholder={t("forms.labels.placeholders.specify")}
                          value={
                            penaltiesSections && penaltiesSections.description
                              ? penaltiesSections.description
                              : ""
                          }
                          isRequired={false}
                          hideLabel={true}
                          onChange={(event) => {
                            handleTextChange(
                              "contractualPenalties",
                              "description",
                              id,
                              event.target.value,
                              setPenaltiesSections,
                              penaltiesSections
                            );
                          }}
                          key={`penalties_description`}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            }
            custom="section"
          />
        </>
      )}
      {costsCodes != null && currencyCodes && costsSections != null && (
        <AccordionCard
          eventKey={7}
          isOpen={true}
          title={`9. ${t("forms.labels.sections.costs")}`}
          body={<CostsDetailsSection />}
          isDisabled={false}
          custom="section"
          customHeader={
            <CustomHeader
              setSection={setCostsSections}
              sections={costsSections}
              type={{
                id: costsSections.length + 1,
                code: null,
                currency: null,
                amount: null,
                specification: null,
              }}
            />
          }
        />
      )}
      {evidenceCodes != null && evidencesSections != null && (
        <AccordionCard
          eventKey={8}
          isOpen={true}
          title={`10. ${t("forms.labels.sections.evidence")}`}
          body={<EvidencesDetailsSection />}
          isDisabled={false}
          custom="section"
          customHeader={
            <CustomHeader
              setSection={setEvidencesSections}
              sections={evidencesSections}
              type={{
                referencedClaimId:
                  "ID" +
                  (evidencesSections.length < 9 ? "0" : "") +
                  (evidencesSections.length + 1),
                code: null,
                description: null,
                date: null,
              }}
            />
          }
        />
      )}

      {additionalStatementsSections != null && groundCodes && (
        <>
          <AccordionCard
            eventKey={9}
            isOpen={true}
            title={`11. ${t("forms.labels.sections.additional_statements")}`}
            body={<AdditionalDetailsSection />}
            isDisabled={false}
            custom="section"
          />
          <div className="section">
            <Card
              header=""
              body={
                <>
                  <Container fluid className="pt-3">
                    <Row>
                      <Col xs={12}>
                        <p className="fw-semibold">
                          {`${t("forms.labels.formA.court_request")}`}
                        </p>
                        <p className="fw-semibold">
                          {`${t("forms.labels.formA.info_declaration")}`}
                        </p>
                        <p className="fw-semibold">
                          {`${t("forms.labels.formA.false_statement_penalty")}`}
                        </p>
                      </Col>
                    </Row>
                  </Container>
                  <div className="section">
                    <Card
                      body={<SignatureContent />}
                      customClass="shadow-none border-secondary"
                    />
                  </div>
                </>
              }
            />
          </div>
        </>
      )}
      {appendix1Sections != null && appendix1Codes && (
        <AccordionCard
          eventKey={10}
          isOpen={true}
          title={`${t("forms.labels.sections.appendix_1")}`}
          body={<Appendix1DetailsSection />}
          isDisabled={false}
          custom="section"
        />
      )}
      {appendix2Sections != null && appendix2Codes && (
        <AccordionCard
          eventKey={1}
          isOpen={true}
          title={`${t("forms.labels.sections.appendix_2")}`}
          body={<Appendix2DetailsSection />}
          isDisabled={false}
          custom="section"
        />
      )}
    </>
  );
};
export default FormA;
