import { useParams } from "react-router-dom";
import EventLineTable from "../Tables/TableLayout/EventLineTable";
import SectionTitle from "../SectionTitle/SectionTitle";
import { useState, useEffect } from "react";
import { fetchCaseHistoryById } from "../../services/apiService";
import { useTranslation } from "react-i18next";

import "./Eventline.scss";

const Eventline = ({ id, isCreateCasePage }) => {
  const [t, i18n] = useTranslation("common");
  let headers = [t("app.general.date"), t("components.history.event"), t("app.general.ref_no"), t("components.history.user"), t("components.history.files")];
  const [caseHistory, setCaseHistory] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCaseHistory = async () => {
      try {
        const response = await fetchCaseHistoryById(id);
        setCaseHistory(response);
      } catch (err) {
        setError("An error occurred while fetching the case history.");
        console.error(err);
      }
    };
    if (!isCreateCasePage) {
      fetchCaseHistory();
    }
  }, [id]);

  return (
    <section
      className={`eventline-section ${isCreateCasePage ? "disabled" : ""}`}
    >
      <SectionTitle
        label={t("components.history.eventline")}
        classn="eventline-title"
      />
      <EventLineTable
        headers={headers}
        label={t("components.history.no_history_yet")}
        data={caseHistory}
      />
    </section>
  );
};

export default Eventline;
