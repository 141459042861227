import React, { useEffect } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import logo from "../../assets/empty-state.png"; // Keep your existing logo
import { useTranslation } from "react-i18next";
const LoadingPage = () => {
  const [t, i18n] = useTranslation("common");
  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center vh-100 bg-light"
    >
      <Row className="text-center">
        <Col>
          {/* Branding Logo */}
          <img
            src={logo} // Replace with your actual logo path
            alt="Icanepo logo"
            className="img-fluid mb-4"
            style={{ width: "150px" }} // Adjust size as needed
          />

          <h1 className="text-primary">{t("app.loadingpage.title")}</h1>
          <p className="text-secondary mb-3">{t("app.loadingpage.message")}</p>

          <p className="text-primary">
            {t("app.loadingpage.timeoutMessage")},{" "}
            <a href="/tasks">{t("app.loadingpage.clickHere")}</a>.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default LoadingPage;
