// errorMessages.js
export const errorMessages = {
  500: {
    type: "danger",
    message: "A server error occurred. Please try again later.",
  },
  401: {
    type: "danger",
    message: "You are not authorized. Please log in again.",
  },
  403: {
    type: "danger",
    message: "You don't have permission to access this resource.",
  },
  404: {
    type: "warning",
    message: "The requested resource was not found.",
  },
  400: {
    type: "warning",
    message: "Bad request. Please check your input.",
  },
  422: {
    type: "warning",
    message: "There was an issue with the data you submitted. Please check and try again.",
  },
  408: {
    type: "warning",
    message: "Request timeout. Please try again.",
  },
  503: {
    type: "danger",
    message: "Service is temporarily unavailable. Please try again later.",
  },
  501: {
    type: "danger",
    message: "The requested feature is not implemented.",
  },
  default: {
    type: "danger",
    message: "An unexpected error occurred. Please try again.",
  },
};
