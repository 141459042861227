import AccordionCard from "../../../components/AccordionCard/AccordionCard";
import FormText from "../../../components/Forms/FormText";
import Col from "react-bootstrap/Col";
import { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import { FormInfo } from "../../../utils/func";
import IconComponent from "../../IconComponent/IconComponent";
import CalendarComponent from "../../Calendar/Calendar";
import { useTranslation } from "react-i18next";
import { Calendar } from "react-iconly";
const Court = ({ id, casedat, setCourtInfoSections, handleTextChange }) => {
  const [formInfo, setformInfo] = useState(null);
  const [receivedByCourt, setReceivedByCourt] = useState(null);
  const [t, i18n] = useTranslation("common");
  useEffect(() => {
    setformInfo(FormInfo(id,t));
  }, [i18n.language]);

  const courtLayout = () => {
    return (
      <>
        <Col md={7}>
          <Row>
            <Col xs={12}>
              <FormText
                label={`${t("forms.labels.general.court_address")}`}
                type="text"
                name="court_address"
                value={
                  casedat && casedat.court && casedat.court.address
                    ? casedat.court.address
                    : ""
                }
                disabled={id != 0 ? true : false}
                isRequired={false}
              />
            </Col>
            <Col md={4}>
              <FormText
                label={`${t("forms.labels.general.city")}`}
                type="text"
                name="court_city"
                value={
                  casedat && casedat.court && casedat.court.city
                    ? casedat.court.city
                    : ""
                }
                disabled={id != 0 ? true : false}
                isRequired={false}
              />
            </Col>
            <Col md={4}>
              <FormText
                label={`${t("forms.labels.general.postal_code")}`}
                type="text"
                name="court_postalCode"
                value={
                  casedat && casedat.court && casedat.court.postalCode
                    ? casedat.court.postalCode
                    : ""
                }
                disabled={id != 0 ? true : false}
                isRequired={false}
              />
            </Col>
            <Col md={4}>
              <FormText
                label={`${t("forms.labels.general.country")}`}
                type="text"
                name="court_country"
                value={
                  casedat && casedat.court && casedat.court.country
                    ? casedat.court.country
                    : ""
                }
                disabled={id != 0 ? true : false}
                isRequired={false}
              />
            </Col>
          </Row>
        </Col>
        <Col md={5} className="form-info-wrapper">
          {formInfo != null && (
            <div className="form-info">
              <h3>{formInfo.title}</h3>
              <p className="span">{formInfo.content}</p>
              {id === 0 && (
                <div className="d-flex align-items-center">
                  <IconComponent icon="" text={`${t("forms.headers.forma_date")}:`} />
                  <CalendarComponent
                    label={t("forms.headers.forma_date")}
                    error={false}
                    isRequired={false}
                    handleChange={(date, value) => {
                      setReceivedByCourt(value);
                    }}
                    date={receivedByCourt ? receivedByCourt : ""}
                  />
                </div>
              )}
            </div>
          )}
        </Col>
      </>
    );
  };

  return (
    <AccordionCard
      eventKey={0}
      isOpen={true}
      title={`1. ${t("forms.labels.sections.court")}`}
      body={courtLayout()}
      isDisabled={id != 0 ? true : false}
      custom={`section half-layout ${id != 0 ? "" : "position-relative z-5"}`}
    />
  );
};

export default Court;
