import Container from "react-bootstrap/Container";
import { Button } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import SectionTitle from "../../../../../components/SectionTitle/SectionTitle";
import CommentsList from "../../../../../components/ScrollableBox/Sidebar/TasksContents/Comments/CommentsList";
import "./DossierContent.scss";

const CommentsPDF = ({ setFormData, user, value, formData, comments }) => {
  const [t, i18n] = useTranslation("common");
  const handleEditorChange = (value) => {
    setFormData({ ...formData, content: value });
  };
  const submitPDFComment = () => {
    console.log("Comment for pdf submit", formData);
    setFormData(null);
  };
  return (
    <div className="comments-section">
      <div className="title-wrapper">
        <SectionTitle
          classn="h3 text-primary title-underline"
          label={t("app.general.comments")}
        />
      </div>
      <CommentsList
        data={comments}
        user={user}
        value={value}
        handleEditorChange={handleEditorChange}
        label="documentcomments"
      />
      <div className="susbmit-comment pb-3">
        <Container fluid>
          <Row className=" justify-content-end">
            <Col xs={"auto"}>
              <Button variant="primary" onClick={submitPDFComment}>
                Add comment
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default CommentsPDF;
