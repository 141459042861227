import Select from "react-select";
import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";

const SelectForm = ({
  label,
  options,
  defaultVal,
  controlId,
  name,
  placeholder,
  disabled,
  onChange,
  value,
  hasExplanation,
  textContent,
  isRequired = false,
  clearSetting = false,
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [selectValue, setSelectValue] = useState(() => {
    // Set initial selectValue from options if defaultVal is provided
    return options.find((option) => option.value === defaultVal) || null;
  });

  const [isClearable] = useState(clearSetting);

  // Update selectValue when options or defaultVal changes
  useEffect(() => {
    if (defaultVal) {
      setSelectValue(options.find((option) => option.value === defaultVal) || null);
    }
  }, [defaultVal, options]);

  const customStyles = {
    input: (provided) => ({
      ...provided,
      color: "#335BAB", // Change the color of the menu input text
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#F1F5F7",
      borderRadius: "3px",
      color: "#264687",
      minWidth: "150px",
      fontWeight: "500",
      fontSize: "0.875rem",
      boxShadow: "none",
      border: "1px solid #94A9D4",
      "&:hover": {
        borderColor: "#94A9D4", // Change border color on hover
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#264687", // Change the color of the dropdown arrow
      "&:hover": {
        color: "#264687", // Change border color on hover
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#264687", // Change the color of the selected value
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "0.875rem",
      padding: "0",
      color: "#264687", // Change the color of the placeholder text here
      whiteSpace: "nowrap", // Prevent the placeholder text from wrapping
      overflow: "hidden", // Hide overflowing text
      textOverflow: "ellipsis", // Add ellipsis when text overflows
      width: "100%",
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: "0px",
      marginBottom: "0px",
      right: 0,
      minWidth: "150px",
      boxShadow: "0px 4px 20px 0px rgba(51, 91, 171, 0.15)",
      backgroundColor: "#F1F5F7",
      fontSize: "0.875rem",
      color: "#335BAB",
      border: "1px solid #94A9D4",
      borderRadius: "3px",
      fontWeight: "500",
      borderTop: 0,
    }),
    indicatorSeparator: () => ({
      display: "none", // Remove the separator
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#264687" : "transparent", // Background color of the selected option in the menu
    }),
  };

  const handleMenuOpen = () => {
    setMenuIsOpen(true);
  };

  const handleMenuClose = () => {
    setMenuIsOpen(false);
  };

  const handlOnChange = (e) => {
    setSelectValue(e); // Update internal state with selected option
    onChange(e); // Notify the parent component about the change
  };

  return (
    <div className="form-wrapper">
      <Form.Label
        htmlFor={controlId}
        className={`${label ? "" : "visually-hidden"}`}
      >{`${label ? label : controlId}`}</Form.Label>
      <Select
        controlId={controlId}
        name={name}
        inputId={controlId}
        aria-labelledby={controlId}
        isClearable={isClearable}
        placeholder={placeholder}
        value={selectValue} // Control the value via state
        options={options}
        required={isRequired}
        onChange={handlOnChange} // Update state on change
        styles={customStyles}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        isDisabled={disabled}
        classNames={{
          menu: () => "custom-menu-class",
        }}
      />
      {hasExplanation && textContent && <small>{textContent}</small>}
    </div>
  );
};

export default SelectForm;
