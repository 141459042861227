import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Calendar } from "react-iconly";
import { useTranslation } from "react-i18next";
import Card from "../../../Card/Card";
import IconComponent from "../../../IconComponent/IconComponent";
import CalendarComponent from "../../../Calendar/Calendar";
import FormText from "../../../Forms/FormText";

const FormG = ({ id, onInputChange, formData }) => {
  const [t, i18n] = useTranslation("common");
  const FormDCardHeader = ({ title, customCls }) => {
    return (
      <Container fluid>
        <Row className="py-3">
          <Col xs={12}>
            <h3 className={`${customCls} text-primary mb-0 h4`}>{title}</h3>
          </Col>
        </Row>
      </Container>
    );
  };

  const FormCardBody = ({ content }) => {
    return (
      <Container fluid>
        <Row>
          <Col xs={12}>
            <p className="text-primary fw-semibold">{content}</p>
          </Col>
        </Row>
      </Container>
    );
  };

  const formDCardBody = (formData) => {
    return (
      <Container fluid>
        <Row className="align-items-center border-top border-3 border-secondary">
          <Col
            md={5}
            className="bg-fadeblue py-3 border-end border-3 border-warning"
          >
            <Row className="align-items-center">
              <Col xl={5}>
                <IconComponent
                  icon={<Calendar label="Calendar" primaryColor="#264687" />}
                  text={`${t("forms.labels.formG.issue_date")}:`}
                  customClass="remove-m"
                />
              </Col>
              <Col xxl={6} xs={12}>
                <CalendarComponent
                  label="issueon_date"
                  handleChange={(date, value) =>
                    onInputChange("issued_on", date, value)
                  }
                  date={
                    formData && formData.issued_on ? formData.issued_on : ""
                  }
                  error={false}
                />
              </Col>
            </Row>
          </Col>
          <Col md={7} className="formd-layout-dates">
            <FormText
              type="text"
              name="against"
              isRequired={false}
              hideLabel={false}
              customClass="fw-normal"
              value={formData && formData.against ? formData.against : ""}
              label={`${t("forms.labels.formG.debtor_name")}:`}
              onChange={(event) =>
                onInputChange("against", "against", event.target.value)
              }
            />
          </Col>
        </Row>
        <Row className="align-items-center border-top border-3 border-secondary">
          <Col
            md={5}
            className="bg-fadeblue py-3 border-end border-3 border-warning"
          >
            <Row className="align-items-center">
              <Col xl={5}>
                <IconComponent
                  icon={<Calendar label="Calendar" primaryColor="#264687" />}
                  text={`${t("forms.labels.formG.service_date")}:`}
                  customClass="remove-m"
                />
              </Col>
              <Col xxl={6} xs={12} className="align-self-end">
                <CalendarComponent
                  label="servedon_date"
                  handleChange={(date, value) =>
                    onInputChange("served_on", date, value)
                  }
                  date={
                    formData && formData.served_on ? formData.served_on : ""
                  }
                  error={false}
                />
              </Col>
            </Row>
          </Col>
          <Col md={7}>
            <p className="text-primary mb-0 span">
              {`${t("forms.labels.formG.enforceability_notice")}`}
            </p>
          </Col>
        </Row>
      </Container>
    );
  };

  return (
    <>
      <div className="section">
        <Card
          header={
            <FormDCardHeader
              title={t("forms.labels.formG.declaration_notice")}
              customCls="fw-semibold"
            />
          }
          body={formDCardBody(formData)}
        />
      </div>
      <div className="section">
        <Card
          header={
            <FormDCardHeader
              title={`${t("forms.labels.formG.important_information_heading")}`}
              customCls="fw-semibold h2 text-center"
            />
          }
          body={
            <FormCardBody content={`${t("forms.labels.formG.enforcement_details")}`} />
          }
        />
      </div>
    </>
  );
};
export default FormG;
