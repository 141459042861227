import { useState,useEffect } from "react";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { FormLabel } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import FormMessage from "../Forms/FormMessage";
import { useTranslation } from "react-i18next";
import "./Calendar.scss";

const CalendarComponent = ({
  label,
  handleChange,
  date,
  error,
  haslabel = false,
  labeltext,
  customClass,
  isRequired = false,
  isRange = false,
  selectedStartDate,
  selectedEndDate

}) => {
  const [selectedDate, setSelectedDate] = useState(date ? date : null); // State to store the selected date
  const [startDate, setStartDate] = useState(selectedStartDate);
  const [endDate, setEndDate] = useState(selectedEndDate);
  const [t, i18n] = useTranslation("common");

  useEffect(() => {
    setStartDate(selectedStartDate);
    setEndDate(selectedEndDate);
  }, [selectedStartDate, selectedEndDate]);

  // Function to handle single or range date selection
  const handleDateChange = (dates) => {
    console.log(dates)
    if (isRange) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
      // Format both start and end dates and pass them to handleChange
      if (start && end) {
        handleChange("duedate_range", {
          start: format(start, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
          end: format(end, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
        });
      }
    } else {
      if(dates){
        setSelectedDate(dates);
        // Format the single date and pass it to handleChange
        handleChange("duedate_at", format(dates, "yyyy-MM-dd'T'HH:mm:ss'Z'"));
      }
    }
  };
  const handleKeyDown = (e) => {
    e.preventDefault(); // Prevent typing
  };
 
  return (
    <div className={`${customClass}`}>
      <FormLabel htmlFor={label} className={haslabel ? "" : "visually-hidden"}>
        {haslabel ? labeltext : t("app.general.dates")}
      </FormLabel>
      <DatePicker
        id={label}
        className="form-control text-mainblue border-1 border-info datepicker-input"
        selected={isRange ? startDate : selectedDate}
        startDate={isRange ? startDate : undefined}
        endDate={isRange ? endDate : undefined}
        selectsRange={isRange} // Enable range selection if isRange is true
        onChange={handleDateChange} // Use the new handleDateChange function
        dateFormat="dd/MM/yyyy" // Specify the date format
        placeholderText={isRange ? t("forms.labels.placeholders.dates") : "--/--/----"}
        autoComplete="off"
        required={isRequired}  
        onKeyDown={handleKeyDown}
      />
      <FormMessage message="Please add a date" error={error} />
    </div>
  );
};

export default CalendarComponent;
