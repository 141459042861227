import { useTranslation } from "react-i18next";
const CaseReference = ({ id }) => {
  const [t, i18n] = useTranslation("common");
  return (
    <div className={`casereference ${id ? "" : "disabled"}`}>
      <span className="p">{t("app.general.casenumber")}: {id ? id : "-"}</span>
    </div>
  );
};

export default CaseReference;
