import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import Documnets from "../DossierTab/DossierContent/Documnets";
import SelectComponent from "../../../../components/Select/Select";
import FormPreview from "./FormPreview";
import casedocs from "../../../../../public/demo/casedocuments.json";
import draftdocs from "../../../../../public/demo/draft_files.json";
import formsjson from "../../../../../public/demo/forms.json";
import draftfileB from "../../../../../public/demo/draft_file_B.json";
import draftfileC from "../../../../../public/demo/draft_file_C.json";
import draftfileD from "../../../../../public/demo/draft_file_D.json";
import draftfileE from "../../../../../public/demo/draft_file_E.json";
import draftfileG from "../../../../../public/demo/draft_file_G.json";
import draftfileFreeMessage from "../../../../../public/demo/draft_file_freeMessage.json";

import "./FormEditor.scss";

const FormEditorTab = ({ id, divRef, casedat, parties }) => {
  const [activePDF, setActivePDF] = useState(null);
  const [activePDFId, setActivePDFId] = useState(null);
  const [drafts, setDrafts] = useState(null);
  const [forms, setForms] = useState(null);
  const [selectedForm, setSelectedForm] = useState(null);
  const [draftFile, setDraftFile] = useState(null);
  const [t, i18n] = useTranslation("common");

//const [participants, setParticipants] = useState(parties);
const getTranslatedOptions = (codes) => {
  if (!codes || !Array.isArray(codes)) return [];
  return codes.map((code) => ({
    value: code.id,
    label: t(`forms.category.${code.name}`, "Unknown name"), // Handle missing name gracefully
  }));
};

useEffect(() => {
  if (formsjson && Array.isArray(formsjson)) {
    const translatedOptions = getTranslatedOptions(formsjson);
    setForms(translatedOptions);
  } else {
    console.error("Invalid formsjson format");
    setForms([]);
  }
}, [i18n.language]);

  useEffect(() => {
    let response = draftdocs;
    setDrafts(draftdocs);
    setActivePDF(response[0].link);
    setActivePDFId(response[0].id);
  }, [id]);

  const handleChangeValues = (name, value) => {
    if (name != "draft") {
      setDraftFile(null);
    }
    setSelectedForm(value);
  };

  const [menuIsOpen] = useState(false);

  let customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#264687",
      borderRadius: "6px",
      boxShadow: "0px 0px 20px 0px rgba(69, 122, 230, 0.20)",
      borderColor: "#264687",
      borderBottomLeftRadius: menuIsOpen ? 0 : "6px",
      borderBottomRightRadius: menuIsOpen ? 0 : "6px",
      borderBottomWidth: menuIsOpen ? "0px" : "1px",
      minWidth: "200px",
      "&:hover": {
        borderColor: "#264687", // Change border color on hover
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "1rem",
      color: "#FAFAFA", // Change the color of the placeholder text here
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#FAFAFA", // Change the color of the dropdown arrow
      "&:hover": {
        color: "#FAFAFA", // Change border color on hover
      },
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: "0px",
      marginBottom: "0px",
      boxShadow: "0px 4px 20px 0px rgba(51, 91, 171, 0.15)",
      minWidth: "200px",
      backgroundColor: "#FAFAFA",
      fontSize: "0.875rem",
      color: "#264687",
      border: "none",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#FAFAFA", // Change the color of the selected value
    }),
    indicatorSeparator: () => ({
      display: "none", // Remove the separator
    }),
    option: (provided, state) => ({
      ...provided,
      color: "#264687",
      backgroundColor: state.isSelected ? "#DBE5FB" : "transparent", // Background color of the selected option in the menu
    }),
    // Add more custom styles as needed
  };

  const setDraftFileFunc = (file, id) => {
    setDraftFile(file);
    handleChangeValues("draft", id);
  };
  const getDraftFile = (id) => {
    switch (id) {
      case 1:
        setDraftFileFunc(draftfileB, draftfileB.form_id);
        break;
      case 2:
        setDraftFileFunc(draftfileC, draftfileC.form_id);
        break;
      case 3:
        setDraftFileFunc(draftfileD, draftfileD.form_id);
        break;
      case 4:
        setDraftFileFunc(draftfileE, draftfileE.form_id);
        break;
      case 5:
        setDraftFileFunc(draftfileG, draftfileG.form_id);
        break;
      case 6:
        setDraftFileFunc(draftfileFreeMessage, draftfileFreeMessage.form_id);
        break;
      default:
        return <div>Error: Invalid File</div>;
    }
  };

  return (
    <>
      {drafts != null && activePDF != null && activePDFId != null && (
        <Container fluid>
          <Row>
            <Col xl={3} className="d-flex flex-column position-md-sticky">
              <Documnets
                data={drafts}
                setActivePDF={setActivePDF}
                activePDFId={activePDFId}
                setActivePDFId={setActivePDFId}
                setDossier={setDrafts}
                divRef={divRef}
                label={`${t("app.general.drafts")}`}
                hasActions={false}
                hasupload={false}
                hasIcons={false}
                type="draft"
                handleClick={getDraftFile}
              />
            </Col>
            <Col xl={9} className="d-flex flex-column">
              <div className="form-select-wrapper flex-xl-nowrap flex-wrap title-underline mt-xl-0 mt-3">
                {selectedForm && (
                  <h2 className="h1 text-primary flex-grow-1 align-self-end mb-xl-0 mb-3 fw-semibold">
                    {
                      forms.filter((item) => item.value === selectedForm)[0]
                        .label
                    }
                  </h2>
                )}

                <SelectComponent
                  handleChange={handleChangeValues}
                  options={forms}
                  controlId="formsStatus"
                  name="forms"
                  placeholder={t("forms.labels.select.create_form")}
                  defaultVal={selectedForm ? selectedForm : ""}
                  isMulti={false}
                  disabled={false}
                  closeMenuOnSelect={true}
                  mystyle={customStyles}
                />
                <div className="btn-wrapper">
                  <Button variant="primary" className="h-auto" disabled>
                    Add translation <Plus size="20px" />
                  </Button>
                </div>
              </div>
              <Row>
                <Col xl={8}>
                  <FormPreview
                    form={selectedForm}
                    casedat={casedat}
                    parties={parties}
                    setSelectedForm={setSelectedForm}
                    draftFile={draftFile}
                    setDraftFile={setDraftFile}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default FormEditorTab;
