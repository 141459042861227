import { useTranslation } from "react-i18next";
import "./LanguageSelector.scss";

const LanguageSelector = () => {
  const [t, i18n] = useTranslation("common");

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("language", lang);
  };

  // Get the current selected language
  const currentLanguage = i18n.language;

  // Function to get the language name based on current language code
  const getLanguageName = (lang) => {
    switch (lang) {
      case "de":
        return t("components.language-selector.languages.de");
      case "en":
        return t("components.language-selector.languages.en");
      case "el":
        return t("components.language-selector.languages.el");
      default:
        return t("components.language-selector.languages.en"); // Default language
    }
  };

  return (
    <div className="language-selector">
      <span className="current-language">
        {getLanguageName(currentLanguage)}
        <i className="mdi mdi-chevron-down align-middle"></i>
      </span>
      <ul className="language-options">
        <li
          onClick={() => changeLanguage("de")}
          className={`nav-item ${currentLanguage === "de" ? "active" : ""}`}
        >
          {t("components.language-selector.languages.de")}
        </li>
        <li
          onClick={() => changeLanguage("en")}
          className={`nav-item ${currentLanguage === "en" ? "active" : ""}`}
        >
          {t("components.language-selector.languages.en")}
        </li>
        <li
          onClick={() => changeLanguage("el")}
          className={`nav-item ${currentLanguage === "el" ? "active" : ""}`}
        >
          {t("components.language-selector.languages.el")}
        </li>
      </ul>
    </div>
  );
};

export default LanguageSelector;
