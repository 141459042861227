import './EmptyState.scss'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useTranslation } from "react-i18next";
function EmptyState() {
  const [t, i18n] = useTranslation("common");
  return (
    <main className="d-flex align-items-center">
      <Container>
        <Row className="justify-content-center text-center ">
          <Col xs={8} >
            <h1>404</h1>
            <p>{t("app.page404.message")}</p>
          </Col>
          <Col xs={8}>
            <Button href="/" variant="primary">{t("components.buttons.go_home")}</Button>
          </Col>
        </Row>
      </Container>
    </main>
    )
}

export default EmptyState
