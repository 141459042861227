import i18next from "i18next";

import common_de from "../public/translations/de/common.json";
import common_en from "../public/translations/en/common.json";
import common_el from "../public/translations/el/common.json";

// Get the user's browser language
const browserLang = navigator.language || navigator.userLanguage; // navigator.language is preferred
const defaultLang = browserLang.split('-')[0];

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: localStorage.getItem("language") || defaultLang || "en", // Use saved language or default to English
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
    },
    de: {
      common: common_de,
    },
    el: {
      common: common_el,
    },
  },
});


