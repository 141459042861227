import { useState, useRef } from "react";

import ListGroup from "react-bootstrap/ListGroup";
import ListItem from "./ListItem/ListItem";
import { useTranslation } from "react-i18next";
import UploadDocument from "./UploadDocument";
import "./ListsPDF.scss";

const ListsPDF = ({
  data,
  setActivePDF,
  activePDFId,
  setActivePDFId,
  setDossier,
  divRef,
  hasActions,
  hasupload,
  hasIcons,
  type,
  handleClick,
  casedat
}) => {
  const [activeItem, setActiveItem] = useState(activePDFId);
  const inputFileRef = useRef(null);
  const [t, i18n] = useTranslation("common");
  const viewPDF = (link, id) => {
    setActivePDF(link);
    setActiveItem(link === activeItem ? null : id);
    setActivePDFId(id);
  };
  // Handle file upload
  const getRandomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  // Handle drag and drop
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    // const file = event.dataTransfer.files[0];
    // // Process the dropped file (e.g., display it, save it to state, etc.)
    // console.log("Dropped file:", file);
    // if (inputFileRef.current) {
    //   inputFileRef.current.files = event.dataTransfer.files;
    // }
    // let newItem = {
    //   id: getRandomInt(4, 100),
    //   is_signed: true,
    //   category: "string",
    //   ref_num: "569848aa",
    //   file_type: "PDF",
    //   name: file.name,
    //   created_at: TimestampConverter({ time: file.lastModified }),
    //   fileType: "form a",
    //   link: "../../../../../../pdf/test1.pdf",
    // };
    // setDossier((prevData) => [...prevData, newItem]);
  };
 

  // Function to handle file change
  const handleFileChange = (file) => {
    // Process the uploaded file (e.g., display it, save it to state, etc.)
    console.log("Uploaded file:", file);
    // Set the file as active or do something else with it
    let newItem = {
      id: getRandomInt(4, 100),
      is_signed: file.is_signed,
      is_digitally_signed: file.is_signed,
      origin: "app_generated",
      ref_num: "DOC67890",
      fileType: "application/pdf",
      name: file.name,
      created_at: "2023-09-15T12:00:00Z",
      form_type: "FormB",
      link: "../../../../../../pdf/test2.pdf",
      comments: [
        {
          id: "d7a123f8-6789-4b67-b891-12345678abcd",
          comment: "Automatically generated by the system.",
          created_by: "system",
          created_at: "2023-09-15T12:10:00Z",
          updated_at: "2023-09-15T12:10:00Z",
        },
      ],
    };

    setDossier((prevData) => [...prevData, newItem]);
  };
  return (
    <div onDragOver={handleDragOver} onDrop={handleDrop}>
      {hasupload && (
        <UploadDocument
          onFileChange={handleFileChange}
          inputFileRef={inputFileRef}
          documents={data}
        />
      )}
      <>
        {data && data.length > 0 ? (
          <ListGroup variant="flush">
            <ListGroup.Item className="no-hover">
              <div className="item-list">
                <div className="ref-number">
                  <span>{`${t("app.general.ref_no")}`}</span>
                </div>
                <div className="pdf-wrapper">
                  <span>{`${t("app.general.title")}`}</span>
                </div>
              </div>
            </ListGroup.Item>
            <>
              {data.map((item, index) => (
                <ListGroup.Item
                  key={index}
                  onClick={() => {
                    if (type != "draft") {
                      viewPDF(item.link, item.id);
                    } else {
                      handleClick(item.id);
                      setActiveItem(item.id);
                    }
                  }}
                  className={activeItem === item.id ? "active" : ""}
                >
                  <ListItem
                    item={item}
                    divRef={divRef}
                    docs={data}
                    hasActions={hasActions}
                    hasIcons={hasIcons}
                    casedat={casedat}
                  />
                </ListGroup.Item>
              ))}
            </>
          </ListGroup>
        ) : (
          <span className="text-primary text-center">{`${t("app.general.no_draft_files_found")}`}</span>
        )}
      </>
    </div>
  );
};

export default ListsPDF;
