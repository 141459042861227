
import { Spinner } from 'react-bootstrap';
import './SpinnerComponent.scss'; // Import the SCSS file for styling

const SpinnerComponent = () => {
  return (
    <div className="spinner-container">
      <Spinner animation="border" variant="primary" size="lg" />
    </div>
  );
};

export default SpinnerComponent;
