import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Form } from "react-bootstrap";
import { Wallet, TimeCircle } from "react-iconly";
import { useState, useEffect } from "react";
import SelectComponent from "../../../Select/Select";

import { HumanizeDateTime } from "../../../../utils/func";
import Details from "../TasksContents/Details";
import SectionTitle from "../TasksContents/SectionTitle";
import FormDescription from "../TasksContents/FormDescription";
import FilesList from "../TasksContents/FilesList";
import History from "../TasksContents/History/History";
import Comments from "../TasksContents/Comments/Comments";
import IconComponent from "../../../IconComponent/IconComponent";
import FormSubmit from "./FormSubmit/FormSubmit";
import { getTask } from "../../../../services/apiService";
import casedocs from "../../../../../public/demo/casedocuments.json";
import { useTranslation } from "react-i18next";
const SingleTask = ({
  data,
  id,
  setData,
  handleChange,
  setFormData,
  formData,
  user,
  options,
  type,
  setRow,
  handleCancel,
  handleSubmit,
  handleFileChange,
  sideNavExpanded,
  setErrorCase,
  caseData,
}) => {
  const [task, setTask] = useState(null);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [alldocuments, setAllDocuments] = useState([]);
  const [errors, setErrors] = useState({
    error_assignee: false,
    error_description: false,
    error_duedate_at: false,
    error_title: false,
  });
  const [t, i18n] = useTranslation("common");
  // Fetch task data and update the state
  useEffect(() => {
    if (!sideNavExpanded) {
      resetErrors();
    }
  }, [sideNavExpanded]);

  const generateCreateTaskJson = () => {
    const newTaskJson =
      type === "createtask"
        ? {
            // CreateTaskRequest structure
            caseId: formData.case_id || null,
            taskId: id || null,
            caseReference: formData.case_reference || null,
            caseEventId: formData.case_event_id || null,
            initialAssignment: {
              dueDateAt: formData.duedate_at || null,
              assigneeId: formData.assignee_id || null,
              assigneeType: formData.assignee_type ?? "USER", // 'USER' or 'GROUP'
              comment: formData.comment || null,
            },
            title: formData.title || "",
            description: formData.description || "",
            associatedFiles:
              selectedDocuments && selectedDocuments.length > 0
                ? selectedDocuments.map((doc) => ({
                    documentId: doc.id, // Adjust if necessary based on how documents are structured
                  }))
                : [], // Empty array if no documents selected
          }
        : {
            // UpdateTaskRequest structure
            taskId: id || null,
            status: formData.status || null, // Status is required in UpdateTaskRequest
            caseId: formData.case_id || null,
            caseReference: formData.case_reference || null,
            caseEventId: formData.case_event_id || null,
            assignment: {
              dueDateAt: formData.duedate_at || null,
              assigneeId: formData.assignee_id || null,
              assigneeType: formData.assignee_type ?? "USER", // 'USER' or 'GROUP'
              comment: formData.comment || null,
            },
            title: formData.title || "",
            description: formData.description || "",
            associatedFiles:
              selectedDocuments && selectedDocuments.length > 0
                ? selectedDocuments.map((doc) => ({
                    documentId: doc.id, // Adjust if necessary based on how documents are structured
                  }))
                : [], // Empty array if no documents selected
          };

    return newTaskJson;
  };

  useEffect(() => {
    if (type === "task" && id) {
      setSelectedDocuments(null);
      setDocuments([]);
      setFormData(null);

      // Use the getTask function from apiService
      getTask(id).then((singleTask) => {
        if (singleTask) {
          setTask(singleTask);
          setFormData({
            ...formData,
            id: singleTask.id,
            status: singleTask.status,
            duedate_at: singleTask.duedate_at || null,
            assignee_id:
              singleTask.assignee_id != undefined
                ? singleTask.assignee_id
                : null,
            case_id:
              singleTask.case_id != undefined ? singleTask.case_id : null,
            attachments: singleTask.attachments || null,
            title: singleTask.title,
            description: singleTask.description,
            created_at: singleTask.created_at,
            related_case:
              singleTask.related_case != undefined
                ? singleTask.related_case
                : null,
          });
          setData(singleTask);

          // Get PDFs from case ID
          if (singleTask.case_id) {
            let pdfs = casedocs;
            setAllDocuments(pdfs);
            setDocuments(
              pdfs.map((item) => ({
                value: item.id,
                label: item.name,
              }))
            );
          }
        }
      });
    } else if (type === "createtask") {
      let response = {
        status: "OPEN",
        case_id: caseData?.id || null,
        caseNumber: caseData?.caseNumber || null,
      };
      if (caseData) {
        if (caseData.id) {
          response.case_id = caseData.id;
        }
        if (caseData.caseNumber) {
          response.related_case = {
            caseNumber: caseData.caseNumber,
          };
        }
      }

      setTask(response);
      setFormData({
        ...formData,
        status: "OPEN",
        case_id: null,
        related_case: null,
        related_files: null,
        comment: null,
        attachments: null,
      });
      setData(response);

      // Get PDFs from case ID
      if (response.caseNumber) {
        let pdfs = casedocs;
        setAllDocuments(pdfs);
        setDocuments(
          pdfs.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        );
      }
    }
  }, [id, type]);

  const handleEditorChange = (value) => {
    setFormData({ ...formData, comment: value });
  };

  const handleChangeRelated = (name, value) => {
    setSelectedDocuments([]);
    var tmp = alldocuments;
    const selectedRelatedArray = value.map((id) =>
      tmp.find((related) => related.id === id)
    );

    handleChange(name, selectedRelatedArray);
    setSelectedDocuments((prevRelated) => [
      ...prevRelated,
      ...selectedRelatedArray,
    ]);
  };

  const resetData = () => {
    setSelectedDocuments(null);
    setFormData(null);
    setRow(null);
    if (type !== "createtask") {
      setDocuments([]);
    }
  };

  const handleSubmitTask = async (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (!form.checkValidity()) {
      //setErrorCase(!formData.case_id);
      e.preventDefault();
      e.stopPropagation();
    } else {
      if (form.checkValidity() === false) {
        const newErrors = {
          error_assignee: !formData.assignee_id,
          error_description: !formData.description,
          error_duedate_at: !formData.created_at,
          error_title: !formData.title,
        };
        //setErrorCase(!formData.case_id);
        setErrors(newErrors);
        e.preventDefault();
        e.stopPropagation();
      } else {
        resetErrors();
        const taskJson = generateCreateTaskJson();
        handleSubmit(e, taskJson);
        resetData();
      }
    }
  };

  const handleCancelTask = (e) => {
    resetErrors();
    handleCancel(e);
    resetData();
  };

  const resetErrors = () => {
    setErrors({
      error_files: false,
      error_assignee: false,
      error_description: false,
      error_duedate_at: false,
      error_title: false,
    });
    //setErrorCase(false);
  };

  const updateError = (field, value) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: value,
    }));
  };

  const handleChangeDetails = (name, value) => {
    handleChange(name, value);
    //updateError("error_" + name, value !== "" ? false : true);
  };
  return (
    <Form noValidate className="sidebar-form" onSubmit={handleSubmitTask}>
      {task != null && data != null && formData && (
        <>
          <Details
            data={task}
            formData={formData}
            handleChange={handleChangeDetails}
            errors={errors}
          />
          <SectionTitle sectionTitle={t("app.general.description")} />
          <FormDescription
            description={task.description}
            handleChange={handleChangeDetails}
            error={errors.error_description}
            label="taskdescription"
          />
          <SectionTitle sectionTitle={`${t("app.general.related_files")}`} />
          <div className="select-component">
            <SelectComponent
              handleChange={handleChangeRelated}
              options={documents}
              controlId="relatedStatus"
              name="related_files"
              placeholder={t("forms.labels.select.add_reference")}
              defaultVal=""
              isMulti={true}
              disabled={false}
              closeMenuOnSelect={false}
              error={errors.error_files}
              isRequired={false}
            />
          </div>
          <FilesList
            data={task.related_files}
            formData={selectedDocuments}
            handleFileChange={handleFileChange}
          />
          {/* <History id={task.id} type={type} /> */}
          <Comments
            id={task.id}
            handleEditorChange={handleEditorChange}
            formData={formData}
            user={user}
            type={type}
          />

          <Container fluid className="sticky-info">
            <Row className="info-row">
              <Col xs="auto">
                <IconComponent
                  icon={<Wallet primaryColor="#264687" />}
                  text={`${t("app.general.task_id")}:`}
                  info={task.id}
                />
              </Col>
              <Col xs="auto">
                <IconComponent
                  icon={<TimeCircle primaryColor="#264687" />}
                  text={`${t("app.general.last_updated")}:`}
                  info={<HumanizeDateTime date={task.updated_at} t={t} />}
                />
              </Col>
            </Row>

            <FormSubmit
              cancelLabel={t("components.buttons.cancel")}
              handleCancel={handleCancelTask}
              submitLabel={
                type === "createtask"
                  ? t("components.buttons.createtask")
                  : t("components.buttons.updatetask")
              }
            />
          </Container>
        </>
      )}
    </Form>
  );
};

export default SingleTask;
