import { useTranslation } from "react-i18next";
import "./SubNavigation.scss";
const SubNavigation = ({ activeTab, setActiveTab, isdisabled }) => {
  const [t, i18n] = useTranslation("common");
  const handleTabChange = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    <div className="subnavigation">
      <ul className="nav nav-tabs flex-nowrap">
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 1 ? "active" : ""}`}
            onClick={() => handleTabChange(1)}
          >
            {`${t("forms.labels.general.details")}`}
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 2 ? "active" : ""}`}
            onClick={() => handleTabChange(2)}
            disabled={isdisabled}
          >
            {`${t("app.general.dossier")}`}
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 3 ? "active" : ""}`}
            onClick={() => handleTabChange(3)}
            disabled={isdisabled}
          >
            {`${t("app.general.form_editor")}`}
          </button>
        </li>
      </ul>
    </div>
  );
};

export default SubNavigation;
