// AlertNotification.js

import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import { useAlert } from "../../contexts/AlertContext";
import { useTranslation } from "react-i18next";
import "./AlertNotification.scss";
const AlertNotification = () => {
  const { alert, hideAlert } = useAlert(); // Get alert state and hideAlert function
  const [isHiding, setIsHiding] = useState(false); // State to handle slide-out
  const [t, i18n] = useTranslation("common");
  // Handle alert hide animation
  const handleHide = () => {
    setIsHiding(true); // Trigger slide-out animation
    setTimeout(() => {
      hideAlert(); // After the slide-out animation ends, hide the alert
    }, 500); // Duration of the slide-out animation (500ms)
  };

  useEffect(() => {
    // Reset hiding state when alert changes
    if (alert.show) {
      setIsHiding(false); // Reset slide-out when a new alert appears
    }
  }, [alert.show]);

  if (!alert.show) return null; // If no alert, don't render anything

  return (
    <div
      className={`centered-alert-container ${isHiding ? "hide" : ""}`}
    >
      <Alert variant={alert.type} onClose={handleHide} dismissible>
        <Alert.Heading>{t('app.notifications.notification_title')}</Alert.Heading>
        <p>{alert.message}</p>
      </Alert>
    </div>
  );
};

export default AlertNotification;
