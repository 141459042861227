// services/apiService.js
import axios from 'axios';
import { API_ENDPOINTS } from "../endpoints/api";
import { Task } from "../models/TaskModel";
import { TaskHistoryResponse } from "../models/HistoryModel";
import { TaskCommentsResponse } from "../models/CommentModel";
import { CaseModel } from "../models/CaseModel";
import { CaseHistory } from "../models/CaseHistoryModel";
import { CaseSearchModel } from "../models/CaseSearchModel";
import { CaseDocumentModel } from "../models/CaseDocumentModel";
import { CaseDraftModel } from "../models/CaseDocumentModel";
import { errorMessages } from '../constants/errorMessages.js'; // Import error messages
export const apiClient = axios.create({
  baseURL: API_ENDPOINTS.BASE_URL, // Set your base URL here
  headers: {
    "Content-Type": "application/json",
  },
});

// Setup API Interceptors
export const setupInterceptors = (showAlert, t) => {
  apiClient.interceptors.response.use(
    (response) => response,
    (error) => {
      const statusCode = error.response?.status;

      // Use errorMessages to handle various error codes
      const errorMessage = errorMessages[statusCode] || errorMessages.default;
      // Show the alert for this error
      showAlert(errorMessage);

      return Promise.reject(error);
    }
  );
};


// Function to fetch all tasks
export const fetchAllTasks = async () => {

  const response = await apiClient.get(API_ENDPOINTS.FETCH_TASKS);
  // Map the response data to TaskListModels instances
  console.log(response.data.map((taskData) => new Task(taskData)));
  return response.data.map((taskData) => new Task(taskData));
};

export const getTask = async (id) => {
  try {
    const response = await apiClient.get(`${API_ENDPOINTS.FETCH_TASKS}/${id}`);
    return new Task(response.data); // Convert response to TaskModel
  } catch (error) {
    console.error("Error importing single task:", error);
    return null;
  }
};

// Fetch task history by task ID
export const fetchTaskHistory = async (taskId) => {
  const response = await apiClient.get(`${API_ENDPOINTS.FETCH_TASKS}/${taskId}/history`);
  return new TaskHistoryResponse(response.data);
};

// Fetch task comments by task ID
export const fetchTaskComments = async (taskId) => {
  const response = await apiClient.get(`${API_ENDPOINTS.FETCH_TASKS}/${taskId}/comments`);
  return new TaskCommentsResponse(response.data);

};

// Function to add a comment to a task
export const addComment = async (taskId, commentData) => {
  try {
    const response = await apiClient.post(`${API_ENDPOINTS.FETCH_TASKS}/${taskId}/comments`, commentData);
    // Return the response if successful
    return response.data;
  } catch (error) {
    console.error("Error adding comment to task:", error);
    throw error; // Rethrow the error to handle it in the calling component
  }
};

export const submitTask = async (formData) => {
  try {
    const response = await apiClient.post(`${API_ENDPOINTS.FETCH_TASKS}`, formData);
    return response.data;
  } catch (error) {
    console.error("Error submitting task:", error);
    throw error;
  }
};

export const updateTask = async (formData) => {
  try {
    const response = await apiClient.patch(`${API_ENDPOINTS.FETCH_TASKS}/${formData.taskId}`, formData);
    return response.data;
  } catch (error) {
    console.error("Error updateTask task:", error);
    throw error;
  }
};


// Function to fetch a case by ID
export const fetchCaseById = async (id) => {
  try {
    const response = await apiClient.get(`${API_ENDPOINTS.FETCH_CASE}/${id}`);
    return new CaseModel(response.data);
  } catch (error) {
    console.error("Error fetching case data", error);
    throw error; // Rethrow the error to handle it in the calling component
  }
};

export const fetchCaseHistoryById = async (id) => {
  try {
    const response = await axios.get(`${API_ENDPOINTS.FETCH_CASE}/${id}/history`);

    return response.data.map((casehistory) => new CaseHistory(casehistory));
  } catch (error) {
    console.error("An error occurred while fetching the case history", error);
    throw error;
  }
};



// Function to fetch cases based on search parameters
export const searchCases = async ({ query, from_date, to_date, page = 1, count = 20 }) => {
  try {
    const response = await apiClient.get(`${API_ENDPOINTS.FETCH_CASE}/search`, {
      params: {
        query,
        from_date,
        to_date,
        page,
        count,
      },
    });
    return response.data.map(caseData => new CaseSearchModel(caseData));
  } catch (error) {
    console.error("Error fetching cases:", error);
    throw error; // Rethrow the error to handle it in the calling component
  }
};

// Function to fetch cases based on search parameters
export const searchRecentCases = async ({ page = 1, count = 20 }, userId) => {
  try {
    const response = await apiClient.get(`${API_ENDPOINTS.FETCH_CASE}/search/${userId}`, {
      params: {
        page,
        count,
      },
    });
    return response.data.map(caseData => new CaseSearchModel(caseData));
  } catch (error) {
    console.error("Error fetching cases:", error);
    throw error; // Rethrow the error to handle it in the calling component
  }
};


export const createCase = async (formData) => {
  try {
    const response = await apiClient.post(`${API_ENDPOINTS.FETCH_CASE}`, formData);
    return response.data;
  } catch (error) {
    console.error("Error create a case:", error);
    throw error;
  }
};

/**
 * Fetch all documents associated with a case.
 * @param {string} caseId - The ID of the case.
 * @returns {Promise<CaseDocumentModel[]>} - An array of case documents.
 */
export const fetchCaseDocuments = async (caseId) => {
  try {
    const response = await apiClient.get(`${API_ENDPOINTS.FETCH_CASE}/${caseId}/documents`);
    return response.data.map((doc) => new CaseDocumentModel(doc)); // Return the documents array
  } catch (error) {
    console.error("Error fetching case documents:", error);
    throw error; // Rethrow the error to handle it in the calling component
  }
};

/**
 * Upload a file for a document to sign it.
 * @param {string} caseId - The ID of the case.
 * @param {SignedDocumentUploadCaseFileRequest} payload - The payload containing file details and signing information.
 * @returns {Promise<CaseDocument>} - The response with file details.
 */
export const uploadSignedDocument = async (caseId, payload) => {
  try {
    const response = await apiClient.post(`${API_ENDPOINTS.FETCH_CASE}/${caseId}/documents/sign/`, payload);
    return new CaseDocumentModel(response.data);
  } catch (error) {
    console.error('Error uploading signed document:', error);
    throw error.response?.data || { message: 'An unexpected error occurred.' };
  }
};


/**
 * Upload a file for a case.
 * @param {string} caseId - The ID of the case.
 * @param {UploadCaseFileRequest} payload - The payload containing file details.
 * @returns {Promise<CaseDocument>} - The response with file details.
 */
export const uploadCaseFile = async (caseId, payload) => {
  try {
    const response = await apiClient.post(`${API_ENDPOINTS.FETCH_CASE}/${caseId}/documents/upload/`, payload);
    return new CaseDocumentModel(response.data);
  } catch (error) {
    console.error('Error uploading case file:', error);
    throw error.response?.data || { message: 'An unexpected error occurred.' };
  }
};


/**
 * Get drafts associated with a case.
 * @param {string} caseId - The ID of the case.
 * @returns {Promise<ICaseDraft[]>} - An array of drafts associated with the case.
 */
export const fetchCaseDrafts = async (caseId) => {
  try {
    const response = await apiClient.get(`${API_ENDPOINTS.FETCH_CASE}/${caseId}/drafts`);
    return new CaseDraftModel(response.data);
  } catch (error) {
    console.error('Error fetching case drafts:', error);
    throw error.response?.data || { message: 'An unexpected error occurred.' };
  }
};


export const submitNote = async (formData) => {
  return apiClient.post('/api/notes', formData);
};

export const submitParticipant = async (formData) => {
  return apiClient.post('/api/participants', formData);
};

export const submitIssue = async (formData) => {
  return apiClient.post('/api/issues', formData);
};
