interface ICaseDocumentModel {
  id: string; // <uuid>
  is_signed: boolean; // <boolean>
  is_digitally_signed: boolean; // <boolean>
  origin: "app_generated" | "user_uploaded"; // <string> enum
  ref_num: string; // <string>
  fileType: string; // <string>
  name: string; // <string>
  created_at: Date; // <dateTime>
  form_type: string; // <string>
  comments?: ICaseDocumentComment[]; // <array of comments>
}

interface ICaseDocumentComment {
  id: string; // <uuid>
  comment: string; // <string> (markdown format)
  created_by: string; // <string>
  created_at: Date; // <dateTime>
  updated_at?: Date; // <dateTime> (optional)
}

export interface ICaseDraft {
  id: number; // Unique identifier for the draft.
  ref_num: string; // Reference number for the draft.
  name: string; // Name of the draft.
  created_at: string; // Date and time when the draft was created.
  form_type: string; // Type of form associated with the draft.
  file_type: string; // Type of the file associated with the draft.
  created_by: string; // The user who created the draft.
}

export class CaseDocumentModel {
  id: string;
  is_signed: boolean;
  is_digitally_signed: boolean;
  origin: "app_generated" | "user_uploaded";
  ref_num: string;
  fileType: string;
  name: string;
  created_at: Date;
  form_type: string;
  comments: CaseDocumentComment[];

  constructor(data: ICaseDocumentModel) {
    this.id = data.id || "";
    this.is_signed = data.is_signed || false;
    this.is_digitally_signed = data.is_digitally_signed || false;
    this.origin = data.origin || "user_uploaded";
    this.ref_num = data.ref_num || "";
    this.fileType = data.fileType || "";
    this.name = data.name || "";
    this.created_at = data.created_at ? new Date(data.created_at) : new Date();
    this.form_type = data.form_type || "";
    this.comments = data.comments
      ? data.comments.map((comment) => new CaseDocumentComment(comment))
      : [];
  }
}

export class CaseDocumentComment {
  id: string;
  comment: string;
  created_by: string;
  created_at: Date;
  updated_at?: Date;

  constructor(data: ICaseDocumentComment) {
    this.id = data.id || "";
    this.comment = data.comment || "";
    this.created_by = data.created_by || "";
    this.created_at = data.created_at ? new Date(data.created_at) : new Date();
    this.updated_at = data.updated_at ? new Date(data.updated_at) : undefined;
  }
}


// Class to map the case draft response into a structured model
export class CaseDraftModel {
  id: number;
  ref_num: string;
  name: string;
  created_at: Date;
  form_type: string;
  file_type: string;
  created_by: string;

  constructor(data: ICaseDraft) {
    this.id = data.id || 0;
    this.ref_num = data.ref_num || "";
    this.name = data.name || "";
    this.created_at = data.created_at ? new Date(data.created_at) : new Date();
    this.form_type = data.form_type || "";
    this.file_type = data.file_type || "";
    this.created_by = data.created_by || "";
  }
}
