import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Plus } from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
const FormCardHeader = ({ title, addAction }) => {
  const [t, i18n] = useTranslation("common");

  return (
    <Container fluid>
      <Row className="py-3">
        <Col xl={9} xs={8}>
          <h3 className="fw-semibold text-primary mb-0 h4">{title}</h3>
        </Col>
        <Col xl={3} xs={4}>
          <Button
            variant="primary"
            className="h-auto ms-auto"
            onClick={addAction}
          >
            {t("components.buttons.add")} <Plus size="20px" />
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default FormCardHeader;
