import { Dropdown, ButtonGroup } from "react-bootstrap";
import { ThreeDots } from "react-bootstrap-icons";
import { CovertDates } from "../../../utils/func";
import { useState, useEffect } from "react";
import { downloadPDF } from "../../../utils/utils";
import { Award } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import OpenSideBar from "../../OpenSideBar/OpenSideBar";

const ListPDF = ({ item, divRef, docs, hasActions, hasIcons, casedat }) => {
  const [sidebarPosition, setsidebarPosition] = useState(0);
  const [sideNavExpanded, setSideNavExpanded] = useState(false);
  const [getRow, setRow] = useState(item.id);
  const [isOpen, setIsOpen] = useState(false);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [t, i18n] = useTranslation("common");
  useEffect(() => {
    const handleResize = () => setScreenHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleAction = (action) => {
    if (action === "Download") {
      downloadPDF(item);
    } else {
      console.log("Action:", action);
    }
  };

  const openSidebar = (event, id) => {
    setIsOpen(true);
    event.preventDefault();
    event.stopPropagation();
    setRow(id);
    setSideNavExpanded(true);
  };

  // Handle sidebar state change
  useEffect(() => {
    const specificElement = document.querySelectorAll(".add-scroll");
    const isSidebarOpen = document.body.classList.contains("sidebar-open");
    if (!isSidebarOpen && sideNavExpanded) {
      document.body.classList.add("sidebar-open");
      specificElement.forEach((elem) => {
        if (elem) {
          elem.style.overflow = "auto";
          elem.style.height = `${screenHeight - 320}px`;
        }
      });
    } else {
      document.body.classList.remove("sidebar-open");
      specificElement.forEach((elem) => {
        if (elem) {
          elem.style.overflow = "";
          elem.style.height = "auto";
        }
      });
    }
    return () => document.body.classList.remove("sidebar-open");
  }, [sideNavExpanded]);
  return (
    <>
      <div className="file-info">
        <div className="file-type-title">
          {hasIcons && (
            <div className="file-type">
              <span>
                {item.fileType === "application/pdf" ? "PDF" : item.fileType}
              </span>
            </div>
          )}

          <span className="file-title">{item.name}</span>
        </div>
        <div className="file-events">
          <span className="file-date">
            <CovertDates date={item.created_at} /> -{" "}
            <span className="file-event">
              {item.form_type} {item.is_signed ? " signed " : ""}uploaded
            </span>
          </span>
        </div>
      </div>

      <>
        {hasActions && (
          <>
            <div className="file-actions">
              <Dropdown as={ButtonGroup}>
                <Dropdown.Toggle
                  variant="link"
                  id="dropdown-basic"
                  className="p-0 actions-field "
                >
                  <ThreeDots width={22} height={22} />
                  <span className="visually-hidden">Actions</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handleAction("Download")}>
                    {t("components.buttons.download")}
                  </Dropdown.Item>
                  {item.is_signed && (
                    <Dropdown.Item onClick={() => openSidebar(event, item.id)}>
                      {t("components.buttons.issue")}
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item onClick={() => handleAction("Delete")}>
                    {t("components.buttons.delete")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>

            {item.is_signed && (
              <div div className="signed_icon">
                <Award size="24px" color="#325499" />
              </div>
            )}

            {item.is_signed && (
              <OpenSideBar
                options={docs}
                type="createissue"
                divRef={divRef}
                setSideNavExpanded={setSideNavExpanded}
                sideNavExpanded={sideNavExpanded}
                sidebarPosition={sidebarPosition}
                setsidebarPosition={setsidebarPosition}
                getRow={getRow}
                setRow={setRow}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                casedat={casedat}
              />
            )}
          </>
        )}
      </>
    </>
  );
};

export default ListPDF;
