import { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Repeater from "../../../Repeater/Repeater";
import Card from "../../../Card/Card";
import ariclescodes from "../../../../../public/demo/articles_codes.json";
import FormCardHeader from "../CardHeader";
import { useTranslation } from "react-i18next";
const FormD = ({
  id,
  articleCodes,
  setArticleCodes,
  articleCodesSections,
  setArticleCodesSections,
  addSection,
  removeSection,
  handleSelectChange,
  handleTextChange,
}) => {
  const [t, i18n] = useTranslation("common");
  useEffect(() => {
    setArticleCodes(ariclescodes);
  }, []);

  const FormCRepeater = ({
    options,
    sections,
    handleTextChange,
    handleSelectChange,
    setSection,
    controlId,
    textname,
    selecteplaceholder,
    textplaceholder,
    type
  }) => {
    return (
      <Container fluid>
        <Row>
          <Col xs={12}>
            <Repeater
              options={options}
              sections={sections}
              handleTextChange={handleTextChange}
              handleSelectChange={handleSelectChange}
              controlId={controlId}
              textname={textname}
              selecteplaceholder={selecteplaceholder}
              textplaceholder={textplaceholder}
              removeSection={(id) => removeSection(id, setSection, sections,type)}
            />
          </Col>
          <Col xs={12} className="border-top border-3 py-3 border-secondary">
            <p className="h4 fw-semibold text-primary">{t("forms.labels.formD.appeal_information")}</p>
          </Col>
        </Row>
      </Container>
    );
  };


  return (
    <div className="section">
      {articleCodes != null && (
        <Card
          header={
            <FormCardHeader
              title={t("forms.labels.formD.rejection_notice")}
              addAction={() =>
                addSection(setArticleCodesSections, articleCodesSections)
              }
            />
          }
          body={
            <FormCRepeater
              options={articleCodes}
              sections={articleCodesSections}
              type="article_code"
              handleTextChange={(id, event) =>
                handleTextChange(
                  "article_code",
                  id,
                  event,
                  setArticleCodesSections,
                  articleCodesSections
                )
              }
              handleSelectChange={(id, event) =>
                handleSelectChange(
                  "article_code",
                  id,
                  event,
                  setArticleCodesSections,
                  articleCodesSections
                )
              }
              setSection={setArticleCodesSections}
              controlId="article_"
              selecteplaceholder="Code"
              textRequired={false}
              textname="code_"
              textplaceholder={t("forms.labels.placeholders.further_info")}
              removeSection={(id) =>
                removeSection(id, setArticleCodesSections, articleCodesSections)
              }
            />
            
          }
        />
      )}
    </div>
  );
};
export default FormD;
