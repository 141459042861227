import React, { useState } from "react";
import Form from "react-bootstrap/Form";

const RadiosForm = ({
  reverse = false,
  label,
  name,
  options = [], // Array of options for radio buttons
  selectedValue,
  disabled,
  onChange,
  hideLabel,
  isRequired,
  customClass,
  layout = "horizontal",
}) => {
  const [currentValue, setCurrentValue] = useState(selectedValue || null);

  const handleChange = (e) => {
    const value = e.target.value;
   
    if (value === currentValue) {
      setCurrentValue(null); // Deselect if the same option is clicked
      onChange && onChange({ target: { value: null } });
    } else {
      setCurrentValue(value);
      onChange && onChange(e);
    }
  };
  const handleClick = (e) => {
    const value = e.target.value;
    console.log(currentValue,value);
    if (value === currentValue) {
      setCurrentValue(null); // Deselect the option on click
      onChange && onChange({ target: { value: null } });
    }
  };

  return (
    <div
      className={`form-wrapper form-text-element mb-0 radio-buttons ${
        layout !== "horizontal" ? layout : ""
      }`}
    >
      {/* <Form.Label
        // htmlFor={name}
        className={`${customClass ? customClass : ""} ${
          hideLabel ? "visually-hidden" : ""
        }`}
      >
        {label}
      </Form.Label> */}

      <div>
        {options.map((option, index) => (
          <Form.Check
            reverse={reverse}
            inline
            key={index}
            type="radio"
            label={option.label}
            name={name + index}
            id={name + index}
            value={option.value}
            checked={currentValue === option.value}
            disabled={disabled}
            onChange={handleChange}
            onClick={handleClick}
            required={isRequired}
          />
        ))}
      </div>
    </div>
  );
};

export default RadiosForm;
