import Table from "react-bootstrap/Table";
import { Dropdown, ButtonGroup } from "react-bootstrap";
import { ThreeDots } from "react-bootstrap-icons";
import Thead from "./Layout/Thead";
import { useTranslation } from "react-i18next";
import "./TableLayout.scss";

const TableParties = ({
  headers,
  label,
  data,
  hasActions,
  openSideBAr,
  setSingleParty,
}) => {
  const [t, i18n] = useTranslation("common");
  const handleAction = (action, e, dat) => {
    // Perform action based on selected dropdown item
    console.log("Action:", action);
    if (action == "Edit") {
      setSingleParty(dat);
      openSideBAr(e);
    }
  };
  const constructFullAddress = (row) => {
    return `${row.address}, ${row.postal_code} ${row.city}, ${row.country}`;
  };
  return (
    <div className="//table-responsive //bg-white">
      <Table hover>
        <Thead headers={headers} hasActions={hasActions} hascolSpan2={false} />
        <tbody>
          <tr key={data.id}>
            <td className="colspan1" colSpan="1">
              {data.first_name + "  " + data.surname}
            </td>
            <td className="colspan1" colSpan="1">
              {constructFullAddress(data)}
            </td>
            <td className="colspan1" colSpan="1">
              {data.phone}
            </td>
            <td className="colspan1" colSpan="1">
              {data.email}
            </td>
            <td className="colspan1" colSpan="1">
              {data.occupation}
            </td>
            {hasActions && (
              <td className="colspan1 " colSpan="1">
                <Dropdown as={ButtonGroup}>
                  <Dropdown.Toggle
                    variant="link"
                    id="dropdown-basic"
                    className="py-0 actions-field"
                  >
                    <ThreeDots />
                    <span className="visually-hidden">Actions</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={(e) => handleAction("Edit", e, data)}
                    >
                      {t("components.buttons.edit")}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => handleAction("Delete", e, data)}
                    >
                      {t("components.buttons.delete")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            )}
          </tr>
        </tbody>
      </Table>
      {/* ) : (
        <div className="d-flex justify-content-center align-items-center empty-state-label">
          <span className="text-mainblue">{label}</span>
        </div>
      )} */}
    </div>
  );
};

export default TableParties;
