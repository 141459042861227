import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormMessage from "../../../Forms/FormMessage";
import { useTranslation } from "react-i18next";
import "easymde/dist/easymde.min.css";
const FormDescription = ({ handleChange, description,error,label }) => {
  const [t, i18n] = useTranslation("common");
  return (
    <Container fluid className="section-border-bottom description-editor">
      <Row>
        <Col xs={12}>
          <Form.Label htmlFor={label} className="visually-hidden">
            {t("forms.labels.placeholders.add_description")}
          </Form.Label>
          <Form.Control
            id={label}
            as="textarea"
            rows={3}
            defaultValue={description}
            className="p-0 add-description m-0"
            placeholder={`${t("forms.labels.placeholders.add_description")}...`}
            onChange={(e) => handleChange("description", e.target.value)}
          />
          <FormMessage message="Please add a description"  error={error}/>
        </Col>
      </Row>
    </Container>
  );
};

export default FormDescription;
