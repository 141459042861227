import {CovertCommentsDates, ExtractInitials} from "../../../../../utils/func";
import { useTranslation } from "react-i18next";
const CommentItem = (props) => {
  const [t, i18n] = useTranslation("common");
  return (
    <div className="comments-item">
      <div className="avatar-text">
        <span><ExtractInitials fullName={props.person} /></span>
      </div>
      <div className="comments-info">
        <div className="comment-date-person">
            <span className="comment-person">{props.person}</span>
            <span className="comment-date"><CovertCommentsDates date={props.date} /></span>
        </div>
        <div className="comment-desc">
            <div dangerouslySetInnerHTML={{ __html: props.comment }}></div>
        </div>
        <div className="comment-actions">
            <a href="#">{t("components.buttons.edit")}</a>
            <a href="#">{t("components.buttons.delete")}</a>
        </div>
      </div>
    </div>
  );
};

export default CommentItem;