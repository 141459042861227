import { useState } from "react";

import { CovertDates } from "../../../../../utils/func";
import InfoCard from "../../../../../components/InfoCard/InfoCard";
import { useTranslation } from "react-i18next";
const InfoPDF = ({ activePDFId }) => {
  const [t, i18n] = useTranslation("common");
  const [metaDataPDF, setMetaDataPDF] = useState({
    filename: t("forms.labels.formA.title"),
    ref_num: "F98459",
    created_at: "2023-12-02 17:19:00",
    updated_at: "2023-12-02 17:19:00",
    craeted_by: "Stefania Giagkou",
  });
  return (
    <>
      <InfoCard label={`${t("app.general.file_name")}`} data={metaDataPDF.filename} />
      <InfoCard label={`${t("app.general.ref_no")}`} data={metaDataPDF.ref_num} />
      <InfoCard label={`${t("app.general.date")}`} data={<CovertDates date={metaDataPDF.created_at} />} />
    </>
  );
};

export default InfoPDF;
